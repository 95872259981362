import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import {
    ActionSuccess,
    IDocument,
    IRemoteApi,
    Serializer,
} from "@codewise/voluum-frontend-framework/repository";
import {
    Feedback,
    IIncomingMessagesRepository,
    Message,
} from "@voluum-panel/assistance/ng-free";
import { HttpStatus } from "@voluum-panel/util/ng-free";
import {
    BehaviorSubject,
    EMPTY,
    interval,
    merge,
    noop,
    Observable,
    of,
    Subject,
    throwError,
} from "rxjs";
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
} from "rxjs/operators";

import { EventBus } from "../../../service/event_bus";
import { EventBusWiredRepository } from "./event-bus-wired";
import { FeedbackRepository } from "./feedback";

export class IncomingMessagesRepository implements IIncomingMessagesRepository {
    public readonly LIMIT: number = 20;
    public readonly messages$: Observable<Message[]>;
    public readonly unseenMessagesCounter$: Observable<number>;
    private readonly deletedMessageSubject$: Subject<Message> = new Subject();
    public readonly changedMessage$: Observable<Message>;
    public readonly deletedMessage$: Observable<Message> =
        this.deletedMessageSubject$.asObservable();
    private readonly unseenMessagesCounterSubject$: BehaviorSubject<number> =
        new BehaviorSubject(0);

    private readonly repo: EventBusWiredRepository<Message>;
    private readonly feedbackRepo: FeedbackRepository;
    private readonly serializer: Serializer<Message> = new Serializer(Message);
    private readonly inboxRefreshInterval: number = 180000;
    private readonly changedMessageSubject$: Subject<Message> = new Subject();

    constructor(
        private readonly appConfig: IAppConfig,
        eventBus: EventBus
    ) {
        this.repo = new EventBusWiredRepository(
            eventBus,
            this.serializer,
            {
                get_all: {
                    method: "GET",
                    // FIXME: query params could be provided via getAllAndStore method
                    url: `${appConfig.server.portal}/notification/inbox?offset=0&limit=${this.LIMIT}`,
                },
                get: {
                    method: "GET",
                    url: `${appConfig.server.portal}/notification/inbox/\${id}`,
                },
                update: {
                    method: "POST",
                    url: `${appConfig.server.portal}/notification/notifications/\${id}/read`,
                },
                delete: {
                    url: `${appConfig.server.portal}/notification/inbox/\${id}`,
                    method: "DELETE",
                },
            } as IRemoteApi,
            {
                readAll: (inboxPayload) => inboxPayload.items,
            } as IDocument
        );

        this.feedbackRepo = new FeedbackRepository(appConfig, eventBus);
        this.messages$ = this.repo.data;
        merge(
            this.pullUnseenMessagesCounter(),
            this.getUnseenMessagesCounter()
        ).subscribe(
            (counter) => this.unseenMessagesCounterSubject$.next(counter),
            noop
        );
        this.unseenMessagesCounter$ =
            this.unseenMessagesCounterSubject$.asObservable();
        this.changedMessage$ = merge(
            this.changedMessageSubject$,
            this.repo.actionSucceeded$.pipe(
                filter(
                    (action) =>
                        action.type === "update" || action.type === "get"
                ),
                map((response: ActionSuccess<Message>) =>
                    Array.isArray(response?.data)
                        ? response.data[0]
                        : response?.data
                )
            )
        );
    }

    public voteUseful(message: Message): Observable<Message> {
        return this.feedbackRepo
            .storeFeedback(message, Feedback.useful())
            .pipe(
                tap((feedbackedMessage) =>
                    this.changedMessageSubject$.next(feedbackedMessage)
                )
            );
    }

    public voteNotUseful(message: Message): Observable<Message> {
        return this.feedbackRepo
            .storeFeedback(message, Feedback.notUseful())
            .pipe(
                tap((feedbackedMessage) =>
                    this.changedMessageSubject$.next(feedbackedMessage)
                )
            );
    }

    public getLatestMessages(): Observable<Message[]> {
        return this.repo
            .getAll()
            .pipe(tap((messages) => this.repo.populate(messages)));
    }

    public markAsRead(message: Message): Observable<Message> {
        return this.repo.updateAndStore(message);
    }

    public markAsUnread(message: Message): Observable<Message> {
        return this.repo.apiService
            .post(
                `${this.appConfig.server.portal}/notification/notifications/unread`,
                {
                    ids: [message.id],
                }
            )
            .pipe(switchMap(() => this.repo.getAndStore(message.id)));
    }

    public delete(message: Message): Observable<Message> {
        return this.repo
            .deleteAndStore(message)
            .pipe(tap(() => this.deletedMessageSubject$.next(message)));
    }

    public markAllAsSeen(): Observable<Message[]> {
        return this.repo.apiService
            .post(
                `${this.appConfig.server.portal}/notification/notifications/seen`,
                null
            )
            .pipe(
                catchError((response) =>
                    response.status === HttpStatus.DEMO_ACCOUNT
                        ? of([])
                        : throwError(response)
                ),
                tap(() => this.unseenMessagesCounterSubject$.next(0))
            );
    }

    private pullUnseenMessagesCounter(): Observable<number> {
        return interval(this.inboxRefreshInterval).pipe(
            mergeMap(() => this.getUnseenMessagesCounter()),
            catchError(() => EMPTY)
        );
    }

    private getUnseenMessagesCounter(): Observable<number> {
        return this.repo.apiService.get(
            `${this.appConfig.server.portal}/notification/inbox/count/unseen`
        );
    }
}
