import { IAppConfig, Time } from "@codewise/voluum-frontend-core/app";
import {
    Client,
    FeatureToggle,
    IMembershipJSON,
    IUserJSON,
    LoginWithGoogle,
    LoginWithMfa,
    Membership,
    MultiuserMigrationStatus,
    Session as SessionModel,
} from "@voluum-panel/profile/ng-free";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
    CLIENT_ID_QUERY_PARAM_NAME,
} from "../../../globals";
import { Ajax } from "../../../service/ajax";
import { Authentication, encodeToken } from "../../../service/authentication";
import { EventBus } from "../../../service/event_bus";
import { INavigationBuilder, Navigation } from "../../../service/navigation";
import { IResponse } from "../../../service/startup";
import { Url } from "../../../service/url";
import { ISessionStatus } from "./monitor";

class SessionManager {
    private _initialUrl: string = window.location.href;
    private _session!: SessionModel;
    private _rawData!: IUserJSON & {
        multiuserStatus?: MultiuserMigrationStatus;
        inauguralLogin?: boolean;
    };
    private readonly _hashPrefix: string = "HASH-";

    get session(): SessionModel {
        return this._session;
    }

    get rawData(): unknown {
        return this._rawData;
    }

    constructor(
        private eventBus: EventBus,
        private appConfig: IAppConfig,
        private time: Time,
        private url: Url
    ) {
        eventBus.onRelogin.subscribe((data: LoginWithMfa) =>
            this.requestMFA(data)
        );
        eventBus.onReloginWithMfa.subscribe((data: LoginWithMfa) =>
            this.renew(data)
        );
        eventBus.onReloginWithGoogle.subscribe((data: LoginWithGoogle) =>
            this.renewWithGoogle(data)
        );
        eventBus.onClientChange.subscribe((client: Client) =>
            this.changeClient(client, true)
        );
        eventBus.onSignOut.subscribe(() => this.signOut());
    }

    public parseResponse(
        sessionResponse: IResponse<IUserJSON>,
        sessionStatusResponse: IResponse<ISessionStatus>,
        featuresResponse: IResponse<IFeatures>
    ): SessionModel | never {
        const failureResponse: IResponse<unknown> | undefined = [
            sessionResponse,
            sessionStatusResponse,
            featuresResponse,
        ].find((response) => response.status !== 200);

        if (failureResponse) {
            throw failureResponse;
        }
        return this.onSessionRequestSuccess(
            sessionResponse.body,
            sessionStatusResponse.body,
            featuresResponse.body
        );
    }

    public requestMFA(data: LoginWithMfa): void {
        Ajax.request({
            url:
                `${this.appConfig.server.portal}/profile/mfa?email=` +
                encodeURIComponent(data.email),
            method: "GET",
        }).subscribe(
            (response: { mfaActive: boolean }) => {
                if (response.mfaActive) {
                    this.eventBus.requestMFACode(data);
                } else {
                    this.renew(data);
                }
            },
            () => this.eventBus.loginFailure()
        );
    }

    public renew(data: LoginWithMfa): void {
        Ajax.request({
            url: `${this.appConfig.server.portal}/auth/session`,
            method: "POST",
            body: {
                email: data.email,
                password: data.password,
                mfaCode: data.mfaCode,
            },
        }).subscribe(
            (response: { token: string }) => {
                this.eventBus.loginSuccessful();
                this.onSessionRenew(response, data.email);
                // We are checking if hash points to empty path right when session expires
                // This situation occurs when user's session expires during page refreshing
                if (window.location.hash === "#/") {
                    window.location.assign(this._initialUrl);
                }
            },
            () => this.eventBus.loginFailure()
        );
    }

    public renewWithGoogle(data: LoginWithGoogle): void {
        Ajax.request({
            url: `${this.appConfig.server.portal}/auth/session/oauth`,
            method: "POST",
            body: {
                authorizationCode: data.authCode,
                redirectUri: window.location.origin,
            },
        }).subscribe(
            (response: { token: string }) => {
                this.eventBus.loginSuccessful();
                this.onSessionRenew(response, data.email);
            },
            () => this.eventBus.loginFailure()
        );
    }

    public changeClient(client: Client, withHash: boolean = false): void {
        if (this.session.currentClient !== client) {
            this.setCurrentClientURLHash();
            const URLHash: string = this.getClientURLHash(client.id);
            const navigationBuilder: INavigationBuilder = withHash
                ? Navigation.builder()
                      .withQuery({ [CLIENT_ID_QUERY_PARAM_NAME]: client.id })
                      .withHash(URLHash)
                      .partially()
                : Navigation.builder()
                      .withQuery({ [CLIENT_ID_QUERY_PARAM_NAME]: client.id })
                      .partially();
            if (this.session.currentClient instanceof Client) {
                navigationBuilder.assign();
            } else {
                navigationBuilder.replace();
            }
            this.eventBus.updateDataLayer({
                event: "GAEvent",
                eventCategory: "User",
                eventAction: "Change client",
            });
        }
    }

    public signOut(): void {
        const token = Authentication.getToken();

        const afterSignOut: () => void = () => {
            this.eventBus.updateDataLayer({
                event: "GAEvent",
                eventCategory: "User",
                eventAction: "Log out",
            });
            this.goToLoginPage();
            Authentication.removeToken();
        };

        if (!token) {
            return afterSignOut();
        }

        Ajax.request({
            method: "DELETE",
            url: `${this.appConfig.server.portal}/auth/session`,
            headers: {
                [AUTHENTICATION_HEADER_NAME]: token,
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(token),
            },
        }).subscribe({
            next: afterSignOut,
            error: afterSignOut,
        });
    }

    public setMultiuserMigratinoStatus(
        migrationStatus: MultiuserMigrationStatus
    ): void {
        this._session.user.multiuserStatus = migrationStatus;
        this._rawData.multiuserStatus = migrationStatus;
    }

    private onSessionRenew({ token }: { token: string }, email?: string): void {
        Authentication.setToken(token);
        if (this.session.user.email !== email) {
            Navigation.builder()
                .withUrl(this.appConfig.frontend.tracker)
                .assign();
        } else {
            this.eventBus.authenticationTokenChanged(token);
        }
    }

    private populateCurrentClient(): SessionModel {
        const clientIdFromURL = this.url.get(CLIENT_ID_QUERY_PARAM_NAME);
        let currentClient: Client | undefined = this.session.user.memberships
            .map((membership: Membership) => membership.client)
            .find((client: Client) => client.id === clientIdFromURL);

        if (!currentClient) {
            currentClient = this.session.user.defaultClient;
            this.changeClient(currentClient);
        }

        this.session.currentClient = currentClient;

        return this.session;
    }

    private onSessionRequestSuccess(
        session: IUserJSON,
        sessionStatus: ISessionStatus,
        featuresData: IFeatures
    ): SessionModel {
        this.populateFeatureToggles(session, featuresData);
        this._rawData = session;
        this._rawData.inauguralLogin = sessionStatus.inaugural;
        this._session = SessionModel.deserializer(session);
        this.session.user.timeZone$.subscribe((timeZone) =>
            this.time.setUserTimeZone(timeZone)
        );
        this.session.user.isFirstLogin = sessionStatus.inaugural;
        this.session.demo = sessionStatus.demo;
        this.time.setServerTime(sessionStatus.time);
        return this.populateCurrentClient();
    }

    private populateFeatureToggles(
        user: IUserJSON,
        featuresData: IFeatures
    ): void {
        const featureToggles: FeatureToggle[] = Object.keys(
            this.appConfig.toggles
        ) as FeatureToggle[];
        const featureTogglesFromConfig: FeatureToggle[] = featureToggles.filter(
            (key: FeatureToggle) => this.appConfig.toggles[key]
        );

        user.memberships.forEach(
            (membership: IMembershipJSON) =>
                (membership.client.toggles = concatUnique(
                    membership.client.toggles,
                    featureTogglesFromConfig,
                    featuresData.features as FeatureToggle[]
                ))
        );
    }

    private goToLoginPage(): void {
        Navigation.builder().withUrl(this.appConfig.frontend.login).replace();
    }

    private setCurrentClientURLHash(): void {
        if (this.session.currentClient) {
            const hash: string = window.location.hash.slice(1);
            sessionStorage.setItem(
                this._hashPrefix + this.session.currentClient.id,
                hash
            );
        }
    }

    private getClientURLHash(clientId: string): string {
        return sessionStorage.getItem(this._hashPrefix + clientId) || " ";
    }
}

function concatUnique<T>(...args: T[][]): T[] {
    return Array.from(new Set(args.reduce((all, one) => all.concat(one), [])));
}

export interface IFeatures {
    features: string[];
}

export { SessionManager, SessionModel as Session };
