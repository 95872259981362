import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable3ReportColumn {
    CUSTOM_VARIABLE_3 = "customVariable3",
    CUSTOM_VARIABLE_3_HASH = "customVariable3Hash",
    CUSTOM_VARIABLE_3_MARKER = "customVariable3Marker",
}

export type CustomVariable3ReportColumns =
    | CustomVariable3ReportColumn
    | CustomVariableReportColumn;
