import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

import { PaymentNotificationEnum } from "./payment-notification.enum";

export class PaymentNotification {
    @AutoSerialize()
    balance: number | undefined;

    @AutoSerialize({ circuitBreaker: () => String })
    notification: PaymentNotificationEnum | undefined;

    @AutoSerialize()
    paymentDeadLine: string | undefined;

    @AutoSerialize()
    owningPaymentProfile: boolean | undefined;

    @AutoSerialize()
    losingCustomDomains: boolean | undefined;

    @AutoSerialize()
    losingUserAccounts: boolean | undefined;
}
