var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { NestedValidation } from "@codewise/voluum-frontend-framework/validation";
import { OperandType } from "./custom-column-operand.model";
import { CustomColumnOperation } from "./custom-column-operation.model";
export class ColumnExpression {
    constructor() {
        this.type = "binaryOperand";
        this.operation = CustomColumnOperation.ADDITION;
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], ColumnExpression.prototype, "type", void 0);
__decorate([
    NestedValidation(),
    AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => ColumnExpression,
    }),
    __metadata("design:type", ColumnExpression)
], ColumnExpression.prototype, "left", void 0);
__decorate([
    NestedValidation(),
    AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => ColumnExpression,
    }),
    __metadata("design:type", ColumnExpression)
], ColumnExpression.prototype, "right", void 0);
__decorate([
    AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.COLUMN,
    }),
    __metadata("design:type", String)
], ColumnExpression.prototype, "name", void 0);
__decorate([
    AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.VALUE,
    }),
    __metadata("design:type", Number)
], ColumnExpression.prototype, "doubleValue", void 0);
__decorate([
    AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => String,
    }),
    __metadata("design:type", typeof (_a = typeof CustomColumnOperation !== "undefined" && CustomColumnOperation) === "function" ? _a : Object)
], ColumnExpression.prototype, "operation", void 0);
