import { EntityId } from "@codewise/voluum-frontend-framework/repository";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

import { Feedback } from "./feedback.model";
import { PushNotification } from "./push-notification.model";

export class Message {
    @EntityId()
    @AutoSerialize()
    public id!: string;

    @AutoSerialize()
    public created!: Date;

    @AutoSerialize()
    public seen?: Date;

    @AutoSerialize()
    public read?: Date;

    @AutoSerialize()
    public payload!: PushNotification;

    @AutoSerialize()
    public feedback?: Feedback;

    @AutoSerialize()
    public clientId!: string;

    get isFeedbacked(): boolean {
        return this.feedback instanceof Feedback;
    }

    get isNotSeen(): boolean {
        return this.seen == null;
    }

    get isNotRead(): boolean {
        return this.read == null;
    }
}
