import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
} from "@codewise/voluum-frontend-framework/model";
import {
    AutoSerialize,
    Serializer,
} from "@codewise/voluum-frontend-framework/repository";

@ReferentiallyImmutable()
export class TimeZone {
    @AutoSerialize()
    @ReferentiallyImmutableKey()
    public code!: string;

    @AutoSerialize()
    public name!: string;

    public toString(): string {
        return this.name;
    }

    public clone(): TimeZone {
        return Serializer.deserialize(TimeZone, Serializer.serialize(this));
    }
}

export const DefaultTimeZone = Serializer.deserialize<TimeZone>(TimeZone, {
    code: "Etc/GMT",
    name: "(UTC) Coordinated Universal Time",
});
