import { Observable, Observer } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { Url } from "./url";

interface INavigationBuilder {
    assign: () => void;
    replace: () => void;
    partially: () => INavigationBuilder;
    withUrl: (url: string) => INavigationBuilder;
    withOrigin: (origin: string) => INavigationBuilder;
    withPath: (path: string) => INavigationBuilder;
    withQuery: (query: unknown) => INavigationBuilder;
    withHash: (hash: string) => INavigationBuilder;
}

class NavigationBuilder implements INavigationBuilder {
    public assign: () => void;
    public replace: () => void;

    private origin?: string;
    private path?: string;
    private url?: string;
    private query?: Record<string, string>;
    private hash?: string;
    private partial?: boolean;

    private urlService: Url = new Url(this.location);

    constructor(private location: Location) {
        this.assign = this.doNavigation.bind(this, "assign");
        this.replace = this.doNavigation.bind(this, "replace");
    }

    public partially(): NavigationBuilder {
        this.partial = true;
        return this;
    }

    public withUrl(fullUrl: string): NavigationBuilder {
        this.url = fullUrl;
        return this;
    }

    public withOrigin(origin: string): NavigationBuilder {
        this.origin = origin;
        return this;
    }

    public withPath(path: string): NavigationBuilder {
        this.path = path;
        return this;
    }

    public withQuery(query: Record<string, string>): NavigationBuilder {
        this.query = query;
        return this;
    }

    public withHash(hash: string): NavigationBuilder {
        this.hash = hash;
        return this;
    }

    private buildUrl(): string {
        let target: string | undefined;
        if (this.url) {
            target = this.url;
        } else if (this.origin) {
            target = this.origin;
            if (!/\/$/.test(target)) {
                target += "/";
            }
            if (this.path) {
                target += this.path.replace(/^\//, "");
            }
        }

        if (!target) {
            throw new Error("'target' is undefined");
        }

        if (this.query) {
            const queryString: string = serializeQuery(this.query);
            target += `?${queryString}`;
        }
        if (this.hash) {
            target += `#${this.hash}`;
        }
        return target;
    }

    private setPartials(): void {
        this.origin = this.origin || this.location.origin;
        this.path = this.path || this.location.pathname || "";
        this.hash = this.hash || this.location.hash.replace(/^#/, "");

        const currentQuery = this.urlService.params;
        if (currentQuery) {
            for (const param in currentQuery) {
                if (this.query && !this.query.hasOwnProperty(param)) {
                    this.query[param] = currentQuery[param];
                }
            }
        }
    }

    private doNavigation(method: "assign" | "replace"): void {
        if (this.partial) {
            this.setPartials();
        }
        this.location[method](this.buildUrl());
    }
}

class Navigation {
    private static hashObserver: Observable<string>;

    public static builder(
        locationService: Location = window.location
    ): INavigationBuilder {
        return new NavigationBuilder(locationService);
    }

    static get onHashChange(): Observable<string> {
        return (
            this.hashObserver ||
            (this.hashObserver = new Observable(
                (observer: Observer<string>): void => {
                    window.addEventListener("hashchange", () =>
                        observer.next(window.location.hash)
                    );
                }
            ).pipe(distinctUntilChanged()))
        );
    }
}

function serializeQuery(query: Record<string, string>): string {
    "use strict";
    return Object.keys(query)
        .map((key: string) => `${key}=${encodeURIComponent(query[key])}`)
        .join("&");
}

export { Navigation };
export type { INavigationBuilder };
