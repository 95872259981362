import { EventName } from "@codewise/voluum-frontend-core/events";
import { IEventsConfig } from "@codewise/voluum-frontend-framework/event_bus_client";

export const events: IEventsConfig = {
    ring: {
        [EventName.MICRO_APP_ALL_READY]: ["ring"],
        [EventName.ROUTE_ACTIVATED]: ["toolbar"],
        [EventName.SHOW_TOAST_NOTIFICATION]: ["toolbar"],
        [EventName.HIDE_TOAST_NOTIFICATION]: ["toolbar"],
        [EventName.SHOW_LOADING]: ["toolbar"],
        [EventName.HIDE_LOADING]: ["toolbar"],
        [EventName.UPDATE_DATA_LAYER]: ["ring"],
        [EventName.SHOW_LOGIN_FORM]: ["forms"],
        [EventName.AUTHENTICATION_TOKEN_CHANGED]: ["*"],
        [EventName.LOGIN_SUCCESSFUL]: ["forms"],
        [EventName.LOGIN_FAILURE]: ["forms"],
        [EventName.SHOW_MFA_FORM]: ["forms"],
        [EventName.CHANGE_PAYMENT_METHOD]: ["forms"],
        [EventName.HTTP_REQUEST_ERROR]: ["ring"],
        [EventName.PLAN_UPGRADED]: ["setup", "forms"],
        [EventName.CHANGE_PLAN]: ["forms"],
        [EventName.OPEN_FULL_PAYMENT_FLOW]: ["forms"],
        [EventName.USER_FLOW_ENDED]: ["forms", "ring"],
        [EventName.NAVIGATE_TO_ENTITY_REPORT_BY_ID]: ["reports"],
    },
};
