import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { bufferCount } from "rxjs/operators";

import { IAppLoadStrategy } from "./app_load_strategy";
import { Apps } from "./apps";
import { EventBus } from "./event_bus";

export class EventWatcher {
    private static APPS_READY_CLASS_NAME: string = "ready";

    constructor(
        private eventBus: EventBus,
        appConfig: IAppConfig,
        appLoadStrategy: IAppLoadStrategy
    ) {
        eventBus.onReady
            .pipe(
                bufferCount(
                    Apps.getActiveApps(appConfig, appLoadStrategy).length
                )
            )
            .subscribe(() => this.onAllReady());
    }

    private onAllReady(): void {
        const readyTimestamp: number = Math.round(performance.now());
        if (window.performance) {
            this.eventBus.updateDataLayer({
                event: "GAEvent",
                eventCategory: "Timings",
                eventAction: "Load time",
                eventValue: readyTimestamp,
            });
        }
        const loader: Element | null = document.querySelector("cw-loader");
        if (loader) {
            document.body.removeChild(loader);
        }
        document.body.classList.add(EventWatcher.APPS_READY_CLASS_NAME);
        this.eventBus.allReady();
    }
}
