import { Cookie } from "./cookie";
export const DEFAULT_TOKEN_COOKIE_NAME = "cw-token-cookie";
export class Authentication {
    static hasToken() {
        return typeof Authentication.getToken() === "string";
    }
    static getToken() {
        return Cookie.get(Authentication.TOKEN_COOKIE_NAME);
    }
    static setToken(value) {
        Cookie.set(Authentication.TOKEN_COOKIE_NAME, value, {
            expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
            domain: window.location.hostname,
        });
    }
    static removeToken() {
        Cookie.delete(Authentication.TOKEN_COOKIE_NAME);
    }
    static initialize(config) {
        if (config && config.environment) {
            Authentication.TOKEN_COOKIE_NAME = DEFAULT_TOKEN_COOKIE_NAME;
        }
        else {
            throw new Error("Please provide a valid app config");
        }
    }
}
Authentication.TOKEN_COOKIE_NAME = DEFAULT_TOKEN_COOKIE_NAME;
export const authenticationPanelTokenHeaderEncoder = (token) => ({
    "cwauth-panel-token": encodeToken(token),
});
export function encodeToken(e) {
    for (var a = 0, r = navigator.userAgent, t = 0; t < r.length; t++)
        (a = (a << 5) - a + r.charCodeAt(t)), (a |= 0);
    var o = atob(e.replace(/_/g, "/").replace(/-/g, "+")), n = "", g = Date.now();
    for (g *= Math.pow(2, g % 16), t = 0; t < o.length; t++)
        n += String.fromCharCode(o.charCodeAt(t) ^
            ((a >>> (8 * (3 - (t % 4)))) & 255) ^
            Math.floor((g / Math.pow(2, 8 * (7 - (t % 8)))) & 255));
    return btoa(n).replace(/\//g, "_").replace(/\+/g, "-");
}
