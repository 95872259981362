import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

/**
 * @see ReportPreferenceModel
 */
export class ColumnModel {
    @AutoSerialize()
    public name!: string;

    @AutoSerialize()
    public visible!: boolean;

    @AutoSerialize()
    public precision?: number;

    @AutoSerialize()
    public width?: number;
}
