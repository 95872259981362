import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

/**
 * @see ReportPreferenceModel
 */
export class SortModel {
    @AutoSerialize()
    public columnName!: string;

    @AutoSerialize()
    public direction!: Direction;
}

export enum Direction {
    ASC = "ASC",
    DESC = "DESC",
}
