import { Ajax } from "./ajax";
import { Cookie } from "./cookie";
import { Navigation } from "./navigation";
export class Invitation {
    constructor(appConfig, url) {
        this.appConfig = appConfig;
        this.url = url;
    }
    get invitationToken() {
        return this.url.get(Invitation.INVITATION_QUERY_PARAM_NAME);
    }
    handleInvitationWhenUserNotLoggedIn() {
        if (this.invitationToken) {
            Ajax.get(`${this.appConfig.server.portal}/multiuser/invitation/${this.invitationToken}`).then((response) => this.onInvitationRequestSuccess(response), () => this.onInvitationRequestError());
        }
    }
    handleInvitationWhenUserLoggedIn(invitationResponse) {
        if (invitationResponse.status !== 200) {
            return this.onInvitationRequestError();
        }
        if (this.invitationToken) {
            Cookie.set(Invitation.INVITATION_COOKIE_NAME, this.invitationToken);
        }
    }
    onInvitationRequestSuccess(response) {
        let target;
        if (response.invitedUserExists && this.invitationToken) {
            Cookie.set(Invitation.INVITATION_COOKIE_NAME, this.invitationToken);
            target = this.appConfig.frontend.login;
        }
        else {
            target = this.appConfig.frontend.registerFinal;
        }
        if (this.invitationToken) {
            Navigation.builder()
                .withUrl(target)
                .withQuery({
                email: response.invitedEmailAddress,
                companyName: response.invitingClient.name,
                ownerFirstName: response.invitingUser.firstName,
                ownerLastName: response.invitingUser.lastName,
                invitationId: this.invitationToken,
            })
                .assign();
        }
    }
    onInvitationRequestError() {
        Navigation.builder()
            .withUrl(this.appConfig.frontend.login)
            .withHash("?reason=invitationAcceptingFailed")
            .assign();
    }
}
Invitation.INVITATION_QUERY_PARAM_NAME = "invitationId";
Invitation.INVITATION_COOKIE_NAME = "cw-invitation-id";
