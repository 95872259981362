import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, } from "@codewise/voluum-frontend-framework/model";
import { AutoSerialize, EntityId, } from "@codewise/voluum-frontend-framework/repository";
import { Required } from "@codewise/voluum-frontend-framework/validation";
let ColumnsOrderTemplate = class ColumnsOrderTemplate {
    constructor() {
        this.orderedColumns = [];
    }
    toString() {
        return this.name;
    }
};
__decorate([
    ReferentiallyImmutableKey(),
    EntityId(),
    AutoSerialize(),
    __metadata("design:type", String)
], ColumnsOrderTemplate.prototype, "id", void 0);
__decorate([
    AutoSerialize(),
    Required(),
    __metadata("design:type", String)
], ColumnsOrderTemplate.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    Required(),
    __metadata("design:type", Array)
], ColumnsOrderTemplate.prototype, "orderedColumns", void 0);
ColumnsOrderTemplate = __decorate([
    ReferentiallyImmutable()
], ColumnsOrderTemplate);
export { ColumnsOrderTemplate };
