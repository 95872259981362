import {
    CustomNotificationEntityType,
    MessageCategory,
    NotificationAction,
    NotificationActionCommand,
} from "@voluum-panel/assistance/ng-free";

import { IActionPattern } from "./pattern";

export function actionMatcher(
    actionPattern: IActionPattern
): (action: NotificationAction) => NotificationActionCommand {
    return function (action: NotificationAction): NotificationActionCommand {
        if (action.data.hasOwnProperty("campaignName")) {
            return actionPattern.ShowCampaign(action.data.campaignName!);
        }
        if (
            action.data.entityType === CustomNotificationEntityType.CAMPAIGN &&
            action.data.hasOwnProperty("entityName")
        ) {
            return actionPattern.ShowCampaign(action.data.entityName!);
        }
        if (action.data.hasOwnProperty("offerName")) {
            return actionPattern.ShowOffer(action.data.offerName!);
        }
        if (
            action.data.entityType === CustomNotificationEntityType.OFFER &&
            action.data.hasOwnProperty("entityName")
        ) {
            return actionPattern.ShowOffer(action.data.entityName!);
        }
        if (
            action.data.entityType === CustomNotificationEntityType.DOMAIN &&
            action.data.hasOwnProperty("domain")
        ) {
            return actionPattern.ShowDomainSettings(action.data.domain!);
        }
        switch (action.category) {
            case MessageCategory.OVERAGES_STARTED:
            case MessageCategory.OVERAGES_HUGE:
                return actionPattern.ShowBillingPage();
            case MessageCategory.PAYMENT_FAILED:
            case MessageCategory.PAYMENT_SUCCEEDED:
                return actionPattern.OpenPaymentMethodPopup();
            case MessageCategory.ANNUAL_PLAN_PROMO:
                return actionPattern.OpenUpgradeAccountPopupOnAnnualTab();
            default:
                return actionPattern.ShowInboxPage();
        }
    };
}
