// re-export models and data that is used in tests and ring (to prevent being dependent on Angular)
export { type ILoadRestAppsIfNeededEvent } from "../../src/lib/event/load-rest-apps-if-needed.event";
export { type IMicroAppAllReadyEvent } from "../../src/lib/event/micro-app-all-ready.event";
export { type IRouteActivatedEvent } from "../../src/lib/event/route-activated.event";
export { type SidenavMode } from "../../src/lib/sidenav-mode/sidenav-mode.type";
export {
    SIDENAV_MODE_BUCKET,
    SIDENAV_MODE_NAMESPACE,
    SIDENAV_MODE_KEY,
} from "../../src/lib/sidenav-mode/sidenav-mode-storage.data";
export { uuid } from "../../src/lib/helper/uuid";
export { HttpStatus } from "../../src/lib/http/http-status.enum";
export { Newsfeed } from "../../src/lib/newsfeed/newsfeed.model";
export { TimeZone } from "../../src/lib/time-zone/time-zone";
