import { IPlanJson, Plan } from "@voluum-panel/profile/ng-free";

import { IResponse } from "../../../service/startup";

class PlanManager {
    private _plan?: Plan;
    private _rawData?: object;

    get plan(): Plan | undefined {
        return this._plan;
    }

    get rawData(): object | undefined {
        return this._rawData;
    }

    public parseResponse(planResponse: IResponse<IPlanJson>): Plan {
        this._rawData = planResponse.body;
        this._plan = Plan.deserializer(planResponse.body);
        return this._plan;
    }

    public updatePlanData(planResponse: IPlanJson): void {
        if (this._plan) {
            this._plan.updatePlanData(planResponse);
            this.updateRawPlanData(planResponse);
        }
    }

    private updateRawPlanData(planResponse: IPlanJson): void {
        Object.assign(this.rawData ?? {}, planResponse);
    }
}

export { PlanManager, Plan };
