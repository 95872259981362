import { IAppConfig } from "@codewise/voluum-frontend-core/app";

import { Ajax } from "./ajax";
import { Navigation } from "./navigation";
import { Url } from "./url";

interface ProfileTokenResponse {
    firstName: string;
    lastName: string;
    email: string;
}

export class ZpToken {
    private static ZP_TOKEN_QUERY_PARAM_NAME: string = "pwdSetToken";

    constructor(
        private appConfig: IAppConfig,
        private url: Url
    ) {}

    get getZPToken(): string | undefined {
        return this.url.get(ZpToken.ZP_TOKEN_QUERY_PARAM_NAME);
    }

    public hasZPToken(): boolean {
        return (
            typeof this.getZPToken === "string" && this.getZPToken.length > 0
        );
    }

    public handleZPToken(): void {
        if (this.hasZPToken()) {
            Ajax.get(
                `${this.appConfig.server.portal}/profile/token?token=${this.getZPToken}`
            ).then(
                (response: ProfileTokenResponse) =>
                    this.onUserDataRequestSuccess(response),
                (response: unknown) => this.onUserDataRequestError(response)
            );
        }
    }

    private onUserDataRequestSuccess(response: ProfileTokenResponse): void {
        if (this.hasZPToken()) {
            Navigation.builder()
                .withUrl(this.appConfig.frontend.registerFinal)
                .withQuery({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    zpToken: this.getZPToken,
                })
                .assign();
        }
    }

    private onUserDataRequestError(_: unknown): void {
        Navigation.builder()
            .withUrl(this.appConfig.frontend.login)
            .withHash("?reason=settingPasswordFailed")
            .assign();
    }
}
