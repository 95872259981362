import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable7ReportColumn {
    CUSTOM_VARIABLE_7 = "customVariable7",
    CUSTOM_VARIABLE_7_HASH = "customVariable7Hash",
    CUSTOM_VARIABLE_7_MARKER = "customVariable7Marker",
}

export type CustomVariable7ReportColumns =
    | CustomVariable7ReportColumn
    | CustomVariableReportColumn;
