import { __awaiter } from "tslib";
import "zone.js";
import "reflect-metadata";
import "./global.scss";
import { Time, } from "@codewise/voluum-frontend-core/app";
import { EventBus as EventBusFactory } from "@codewise/voluum-frontend-framework/event_bus_client";
import { Serializer } from "@codewise/voluum-frontend-framework/repository";
import { PromoSummary, } from "@voluum-panel/assistance/ng-free";
import { CustomConversion } from "@voluum-panel/entities/ng-free";
import { BillingInfo, ClientBasicInfoModel, ColumnsOrderTemplate, PaymentNotification, QuotaLimits, UserPreferenceModel, } from "@voluum-panel/profile/ng-free";
import { CustomColumn } from "@voluum-panel/report/ng-free";
import { HttpStatus, TimeZone } from "@voluum-panel/util/ng-free";
import * as moment from "moment-timezone";
import { forkJoin, map } from "rxjs";
import * as WebFont from "webfontloader";
import { FatalError as FatalErrorComponent } from "./component/fatal_error/fatal_error";
import { events } from "./events";
import { Freshdesk } from "./module/analytics/app/freshdesk";
import { Service as Analytics } from "./module/analytics/service";
import { Coupon as CouponManager } from "./module/coupon/manager/coupon";
import { MultiuserStatusManager } from "./module/multiuser/manager/multiuser";
import { PlanMonitor } from "./module/plan/service/monitor";
import { PlanManager } from "./module/plan/service/plan";
import { PushNotifications } from "./module/push-notifications/service/push-notifications";
import { SessionManager } from "./module/session/service/manager";
import { SessionMonitor } from "./module/session/service/monitor";
import { WorkspacesManager } from "./module/workspaces/service/workspaces";
import { AppLoadStrategyService } from "./service/app_load_strategy";
import { Apps } from "./service/apps";
import { Authentication } from "./service/authentication";
import { ClientId } from "./service/client_id";
import { Config } from "./service/config";
import { Datadog } from "./service/datadog";
import { DocumentationLinks } from "./service/doc_links";
import { Environment } from "./service/environment";
import { EventBus as EventBusService } from "./service/event_bus";
import { EventWatcher } from "./service/event_watcher";
import { HttpRequestErrorHandler } from "./service/http_request_error_handler";
import { Invitation } from "./service/invitation";
import { LoginHandler } from "./service/login-handler";
import { Navigation } from "./service/navigation";
import { NewsfeedService } from "./service/newsfeed";
import { Router } from "./service/router";
import { SideNavWidthService } from "./service/side-nav-width-service";
import { Startup } from "./service/startup";
import { Url } from "./service/url";
import { ZpToken } from "./service/zp_token";
window.moment = moment;
class App {
    constructor() {
        this.APP_NAME = "ring";
        this.AUTHORIZATION_EXCEPTION = new Error("UNAUTHORIZED");
        this.customColumns = [];
        this.customConversion = [];
        this.timezones = [];
        this.columnOrderTemplates = [];
        this.preloadFonts();
    }
    run() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            try {
                SideNavWidthService.determineMode();
                const [appConfig, documentationLinks, newsfeed] = yield Promise.all([
                    Config.getAppConfig(),
                    DocumentationLinks.load(),
                    NewsfeedService.load(),
                ]);
                Authentication.initialize(appConfig);
                this.initializeServices(appConfig, documentationLinks, newsfeed);
                if (!Authentication.hasToken()) {
                    throw this.AUTHORIZATION_EXCEPTION;
                }
                if (!this.clientId) {
                    throw new Error("'clientId' not initialized!");
                }
                if (!this.startup) {
                    throw new Error("'startup' not initialized!");
                }
                if (!this.sessionManager) {
                    throw new Error("'sessionManager' not initialized!");
                }
                if (!this.planManager) {
                    throw new Error("'planManager' not initialized!");
                }
                if (!this.couponManager) {
                    throw new Error("'couponManager' not initialized!");
                }
                if (!this.workspacesManager) {
                    throw new Error("'workspacesManager' not initialized!");
                }
                if (!this.multiuserStatusManager) {
                    throw new Error("'multiuserStatusManager' not initialized!");
                }
                if (!this.pushNotifications) {
                    throw new Error("'pushNotifications' not initialized!");
                }
                if (!this.invitation) {
                    throw new Error("'invitation' not initialized!");
                }
                if (!this.sessionMonitor) {
                    throw new Error("'sessionMonitor' not initialized!");
                }
                if (!this.eventBusService) {
                    throw new Error("'eventBusService' not initialized!");
                }
                if (!this.analytics) {
                    throw new Error("'analytics' not initialized!");
                }
                if (!this.datadog) {
                    throw new Error("'datadog' not initialized!");
                }
                const clientId = yield this.clientId.retrieveClientId().toPromise();
                if (!clientId) {
                    throw new Error("'clientId' not found!");
                }
                const startupResponse = yield forkJoin([
                    this.startup.load(clientId),
                    ...Apps.load(appConfig, AppLoadStrategyService.getAppLoadStrategy()),
                ])
                    .pipe(map(([startup]) => startup))
                    .toPromise();
                if (!startupResponse) {
                    throw new Error("'startupResponse' not found!");
                }
                this.startupResponse = startupResponse;
                const plan = this.planManager.parseResponse(startupResponse.plan);
                const session = yield this.sessionManager.parseResponse(startupResponse.user, startupResponse.session, startupResponse.features);
                if (!session) {
                    throw new Error("'session' not found!");
                }
                if (!plan) {
                    throw new Error("'plan' not found!");
                }
                this.clientBasicInfo = Serializer.deserialize(ClientBasicInfoModel, startupResponse.clientBasicInfo.body);
                this.preferences = Serializer.deserialize(UserPreferenceModel, startupResponse.preferences.body);
                this.customColumns = Serializer.deserialize(CustomColumn, (_c = (_b = (_a = startupResponse.customColumns) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b["customColumnsDefinitions"]) !== null && _c !== void 0 ? _c : []);
                this.customConversion = Serializer.deserialize(CustomConversion, (_f = (_e = (_d = startupResponse.customConversions) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e["customConversions"]) !== null && _f !== void 0 ? _f : []);
                this.promoSummary = startupResponse.promoSummary
                    ? Serializer.deserialize(PromoSummary, startupResponse.promoSummary.body)
                    : new PromoSummary();
                this.paymentNotification = startupResponse.notification
                    ? Serializer.deserialize(PaymentNotification, startupResponse.notification.body)
                    : new PaymentNotification();
                this.billingInfo = startupResponse.billingInfo
                    ? Serializer.deserialize(BillingInfo, startupResponse.billingInfo.body)
                    : new BillingInfo();
                this.quotaLimits = startupResponse.entityQuota
                    ? Serializer.deserialize(QuotaLimits, startupResponse.entityQuota.body)
                    : new QuotaLimits();
                this.timezones = startupResponse.timezones
                    ? Serializer.deserialize(TimeZone, startupResponse.timezones.body.timezones)
                    : [];
                this.columnOrderTemplates = startupResponse.columnOrderTemplates
                    ? Serializer.deserialize(ColumnsOrderTemplate, startupResponse.columnOrderTemplates.body)
                    : [];
                this.sessionMonitor.init();
                this.datadog.setUserContext(session);
                this.couponManager.parseResponse(startupResponse.coupon);
                this.workspacesManager.parseResponseAndAssignWorkspaces(startupResponse.workspaces);
                this.multiuserStatusManager.parseResponse(startupResponse.multiUser);
                yield this.pushNotifications.initialize();
                if (this.invitation.invitationToken) {
                    this.invitation.handleInvitationWhenUserLoggedIn(startupResponse.invitation);
                }
                LoginHandler.init(clientId, session.user.email, this.eventBusService);
                Apps.instantiate(appConfig, AppLoadStrategyService.getAppLoadStrategy()).forEach((app) => this.bootstrapApp(app));
                this.analytics.initialize(session, plan, this.preferences, this.promoSummary);
                new PlanMonitor(appConfig, this.eventBusService, this.planManager, session);
                new Freshdesk(this.eventBusService, session, appConfig);
                if (AppLoadStrategyService.isFirstLoad()) {
                    AppLoadStrategyService.manageFirstLoad(this);
                }
            }
            catch (exception) {
                this.onError(exception);
            }
        });
    }
    bootstrapApp(app) {
        if (app.isAngularApp) {
            return this.bootstrapAngularApp(app);
        }
    }
    initializeServices(appConfig, documentationLinks, newsfeed) {
        const url = new Url();
        this.datadog = new Datadog(appConfig);
        this.appConfig = appConfig;
        this.documentationLinks = documentationLinks;
        this.newsfeed = newsfeed;
        this.invitation = new Invitation(appConfig, url);
        this.startup = new Startup(appConfig, this.invitation);
        this.zpToken = new ZpToken(appConfig, url);
        this.eventBusFactory = new EventBusFactory(events, Apps.getActiveAppNames(appConfig));
        this.eventBusService = new EventBusService(this.eventBusFactory.createEventBusClient(this.APP_NAME));
        this.environment = new Environment(appConfig);
        this.time = new Time();
        const httpRequestErrorHandler = new HttpRequestErrorHandler(this.eventBusService);
        httpRequestErrorHandler.init();
        this.sessionManager = new SessionManager(this.eventBusService, appConfig, this.time, url);
        this.sessionMonitor = new SessionMonitor(appConfig, this.eventBusService, this.time, httpRequestErrorHandler);
        this.planManager = new PlanManager();
        this.couponManager = new CouponManager();
        this.workspacesManager = new WorkspacesManager(this.eventBusService);
        this.multiuserStatusManager = new MultiuserStatusManager(this.sessionManager);
        this.eventWatcher = new EventWatcher(this.eventBusService, appConfig, AppLoadStrategyService.getAppLoadStrategy());
        this.clientId = new ClientId(appConfig, url);
        this.analytics = new Analytics(this.appConfig, this.eventBusService);
        this.pushNotifications = new PushNotifications(appConfig, this.eventBusService);
        new Router(this.eventBusService, appConfig, this.sessionManager);
    }
    onError(error) {
        var _a;
        if (isAbortedAjaxError(error)) {
            return;
        }
        if (this.isAuthorizationError(error)) {
            return this.handleAuthorizationError();
        }
        FatalErrorComponent.show();
        if (!isAjaxError(error)) {
            (_a = this.datadog) === null || _a === void 0 ? void 0 : _a.addError(error);
        }
        console.error(error);
    }
    bootstrapAngularApp(app) {
        if (this.appConfig &&
            this.eventBusFactory &&
            this.sessionManager &&
            this.planManager &&
            this.planManager.plan &&
            this.couponManager &&
            this.time &&
            this.environment &&
            this.workspacesManager &&
            this.newsfeed &&
            this.preferences &&
            this.promoSummary &&
            this.quotaLimits &&
            this.paymentNotification &&
            this.billingInfo) {
            const options = {
                session: this.sessionManager.session,
                plan: this.planManager.plan,
                coupons: this.couponManager.coupons,
                documentationLinks: this.documentationLinks,
                workspacesManager: this.workspacesManager,
                pushNotifications: this.pushNotifications,
                newsfeed: this.newsfeed,
                preferences: this.preferences,
                promoSummary: this.promoSummary,
                quotaLimits: this.quotaLimits,
                paymentNotification: this.paymentNotification,
                billingInfo: this.billingInfo,
                isFirstLoad: AppLoadStrategyService.isFirstLoad(),
                customColumns: this.customColumns,
                customConversions: this.customConversion,
                timezones: this.timezones,
                columnOrderTemplates: this.columnOrderTemplates,
                clientBasicInfo: this.clientBasicInfo,
            };
            app.bootstrap({
                appConfig: this.appConfig,
                eventBusFactory: this.eventBusFactory,
                prodMode: this.environment.isProductionMode,
                time: this.time,
                authorizationToken: Authentication.getToken(),
            }, options);
        }
    }
    goToLoginPage() {
        if (this.appConfig && this.appConfig.frontend) {
            Navigation.builder()
                .withUrl(this.appConfig.frontend.login)
                .replace();
        }
    }
    handleAuthorizationError() {
        Authentication.removeToken();
        if (this.zpToken && this.zpToken.hasZPToken()) {
            this.zpToken.handleZPToken();
        }
        else if (this.invitation && this.invitation.invitationToken) {
            this.invitation.handleInvitationWhenUserNotLoggedIn();
        }
        else {
            this.goToLoginPage();
        }
    }
    isAuthorizationError(error) {
        return (error === this.AUTHORIZATION_EXCEPTION ||
            (isAjaxError(error) &&
                [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(error.status)));
    }
    preloadFonts() {
        WebFont.load({
            google: {
                families: [
                    "Roboto:300,400,500,700",
                    "Montserrat:300,400,500,700",
                ],
            },
        });
    }
}
function isAjaxError(error) {
    return error.hasOwnProperty("status");
}
function isAbortedAjaxError(error) {
    return isAjaxError(error) && error.status === XMLHttpRequest.UNSENT;
}
export default App;
