import { __decorate, __metadata } from "tslib";
import { AutoSerialize, AutoSerializeCollection, } from "@codewise/voluum-frontend-framework/serialization";
export class NewsfeedItem {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], NewsfeedItem.prototype, "title", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], NewsfeedItem.prototype, "description", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], NewsfeedItem.prototype, "date", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], NewsfeedItem.prototype, "docLink", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], NewsfeedItem.prototype, "isNew", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], NewsfeedItem.prototype, "cta", void 0);
export class Newsfeed {
    constructor() {
        this.newsfeedData = [];
    }
}
__decorate([
    AutoSerializeCollection(NewsfeedItem),
    __metadata("design:type", Array)
], Newsfeed.prototype, "newsfeedData", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], Newsfeed.prototype, "timestamp", void 0);
