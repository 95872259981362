import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
export class BillingAddress {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingAddress.prototype, "addressLine", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingAddress.prototype, "city", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingAddress.prototype, "country", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingAddress.prototype, "zipCode", void 0);
