import { Coupons, ICouponsJson } from "@voluum-panel/profile/ng-free";

import { IResponse } from "../../../service/startup";

export class Coupon {
    private _coupon: Coupons = new Coupons();

    get coupons(): Coupons {
        return this._coupon;
    }

    public parseResponse(couponResponse: IResponse<ICouponsJson>): Coupons {
        if (couponResponse?.status === 200) {
            this._coupon = Coupons.deserializer(couponResponse.body);
        }
        return this._coupon;
    }
}
