import { AffiliateNetworkReportColumn } from "./affiliate-network-report-column.enum";
import { BrowserReportColumn } from "./browser-report-column.enum";
import { BrowserVersionReportColumn } from "./browser-version-report-column.enum";
import { CampaignReportColumn } from "./campaign-report-column.enum";
import { CityReportColumn } from "./city-report-column.enum";
import { ConnectionTypeReportColumn } from "./connection-type-report-column.enum";
import { ConversionClickIdReportColumn } from "./conversion-click-id-report-column.enum";
import { ConversionCommonReportColumn } from "./conversion-common-report-column.enum";
import { ConversionExternalIdReportColumn } from "./conversion-external-id-report-column.enum";
import { ConversionPostbackParam1ReportColumn } from "./conversion-postback-param-1-report-column.enum";
import { ConversionPostbackParam2ReportColumn } from "./conversion-postback-param-2-report-column.enum";
import { ConversionPostbackParam3ReportColumn } from "./conversion-postback-param-3-report-column.enum";
import { ConversionPostbackParam4ReportColumn } from "./conversion-postback-param-4-report-column.enum";
import { ConversionPostbackParam5ReportColumn } from "./conversion-postback-param-5-report-column.enum";
import { ConversionTransactionIdReportColumn } from "./conversion-transaction-id-report-column.enum";
import { CountryReportColumn } from "./country-report-column.enum";
import { CustomVariable1ReportColumn } from "./custom-variable-1-report-column.enum";
import { CustomVariable2ReportColumn } from "./custom-variable-2-report-column.enum";
import { CustomVariable3ReportColumn } from "./custom-variable-3-report-column.enum";
import { CustomVariable4ReportColumn } from "./custom-variable-4-report-column.enum";
import { CustomVariable5ReportColumn } from "./custom-variable-5-report-column.enum";
import { CustomVariable6ReportColumn } from "./custom-variable-6-report-column.enum";
import { CustomVariable7ReportColumn } from "./custom-variable-7-report-column.enum";
import { CustomVariable8ReportColumn } from "./custom-variable-8-report-column.enum";
import { CustomVariable9ReportColumn } from "./custom-variable-9-report-column.enum";
import { CustomVariable10ReportColumn } from "./custom-variable-10-report-column.enum";
import { DeviceBrandReportColumn } from "./device-brand-report-column.enum";
import { DeviceModelReportColumn } from "./device-model-report-column.enum";
import { DeviceTypeReportColumn } from "./device-type-report-column.enum";
import { IpReportColumn } from "./ip-report-column.enum";
import { IspReportColumn } from "./isp-report-column.enum";
import { LanderReportColumn } from "./lander-report-column.enum";
import { MobileCarrierReportColumn } from "./mobile-carrier-report-column.enum";
import { OfferReportColumn } from "./offer-report-column.enum";
import { OsReportColumn } from "./os-report-column.enum";
import { OsVersionReportColumn } from "./os-version-report-column.enum";
import { PathReportColumn } from "./path-report-column.enum";
import { ReferrerReportColumn } from "./referrer-report-column.enum";
import { RegionReportColumn } from "./region-report-column.enum";
import { TrafficSourceReportColumn } from "./traffic-source-report-column.enum";

export enum ConversionReportColumn {
    CONVERSION_ORIGINAL_CURRENCY = "conversionOriginalCurrency",
    CONVERSION_TYPE = "conversionType",
    CONVERSION_OUTGOING_POSTBACK_URL = "outgoingPostbackUrl",
    INCOMING_POSTBACK_URL = "incomingPostbackUrl",
    INCOMING_POSTBACK_IP = "incomingPostbackIp",
}

export type ConversionReportColumns =
    | ConversionReportColumn
    | ConversionCommonReportColumn
    | CampaignReportColumn
    | LanderReportColumn
    | OfferReportColumn
    | CountryReportColumn
    | RegionReportColumn
    | CityReportColumn
    | PathReportColumn
    | TrafficSourceReportColumn
    | AffiliateNetworkReportColumn
    | DeviceTypeReportColumn
    | OsReportColumn
    | OsVersionReportColumn
    | DeviceBrandReportColumn
    | DeviceModelReportColumn
    | BrowserReportColumn
    | BrowserVersionReportColumn
    | IspReportColumn
    | MobileCarrierReportColumn
    | ConnectionTypeReportColumn
    | IpReportColumn
    | ReferrerReportColumn
    | CustomVariable1ReportColumn
    | CustomVariable2ReportColumn
    | CustomVariable3ReportColumn
    | CustomVariable4ReportColumn
    | CustomVariable5ReportColumn
    | CustomVariable6ReportColumn
    | CustomVariable7ReportColumn
    | CustomVariable8ReportColumn
    | CustomVariable9ReportColumn
    | CustomVariable10ReportColumn
    | ConversionPostbackParam1ReportColumn
    | ConversionPostbackParam2ReportColumn
    | ConversionPostbackParam3ReportColumn
    | ConversionPostbackParam4ReportColumn
    | ConversionPostbackParam5ReportColumn
    | ConversionClickIdReportColumn
    | ConversionExternalIdReportColumn
    | ConversionTransactionIdReportColumn;
