var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { ColumnsOrderTemplate } from "../../columns-order/model";
export class OrderedColumnsConfigurationModel {
    constructor() {
        this.key = [];
        this.value = [];
        this.frozen = [];
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], OrderedColumnsConfigurationModel.prototype, "key", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], OrderedColumnsConfigurationModel.prototype, "value", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], OrderedColumnsConfigurationModel.prototype, "active", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], OrderedColumnsConfigurationModel.prototype, "frozen", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof ColumnsOrderTemplate !== "undefined" && ColumnsOrderTemplate) === "function" ? _a : Object)
], OrderedColumnsConfigurationModel.prototype, "columnOrderTemplateId", void 0);
