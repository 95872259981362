import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable8ReportColumn {
    CUSTOM_VARIABLE_8 = "customVariable8",
    CUSTOM_VARIABLE_8_HASH = "customVariable8Hash",
    CUSTOM_VARIABLE_8_MARKER = "customVariable8Marker",
}

export type CustomVariable8ReportColumns =
    | CustomVariable8ReportColumn
    | CustomVariableReportColumn;
