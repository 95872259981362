import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
} from "@codewise/voluum-frontend-framework/model";
import {
    EntityDeserializer,
    EntitySerializer,
} from "@codewise/voluum-frontend-framework/repository";

@ReferentiallyImmutable()
export class Workspace {
    @ReferentiallyImmutableKey()
    public id: string | null;

    public name: string | undefined;

    @EntityDeserializer()
    public static deserializer(data: { id: string; name: string }): Workspace {
        return new Workspace(data.id, data.name);
    }

    public static isSharedWorkspace(workspace: Workspace): boolean {
        return !workspace || workspace === SHARED_WORKSPACE;
    }

    public static create(id: string, name?: string): Workspace {
        return id === SHARED_WORKSPACE.id
            ? SHARED_WORKSPACE
            : new Workspace(id, name);
    }

    constructor(id: string | null, name: string | undefined) {
        this.id = id;
        this.name = name;
    }

    @EntitySerializer()
    public serializer(): { id: string | null } | null {
        return Workspace.isSharedWorkspace(this) ? null : { id: this.id };
    }

    public toString(): string | null | undefined {
        return Workspace.isSharedWorkspace(this) ? null : this.name;
    }
}

export interface IEntityWithWorkspace {
    workspace: Workspace;
    workspaceIsRequired: boolean;
    id: string;
}

export const SHARED_WORKSPACE: Workspace = new Workspace(
    "shared",
    "Not assigned to a workspace"
);
