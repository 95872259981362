import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

export class Feedback {
    public static USEFUL_SCORE: number = 5;
    public static NOT_USEFUL_SCORE: number = 1;

    @AutoSerialize()
    public readonly score: number;

    @AutoSerialize()
    public comment!: string;

    public static useful(): Feedback {
        return new Feedback(Feedback.USEFUL_SCORE);
    }

    public static notUseful(): Feedback {
        return new Feedback(Feedback.NOT_USEFUL_SCORE);
    }

    public get isUseful(): boolean {
        return this.score === Feedback.USEFUL_SCORE;
    }

    public get isNotUseful(): boolean {
        return this.score === Feedback.NOT_USEFUL_SCORE;
    }

    private constructor(score: number) {
        this.score = score;
    }
}
