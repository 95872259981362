export class FatalError {
    public static show(): FatalError {
        return new FatalError();
    }

    constructor() {
        this.render();
    }

    private render(): void {
        const el: HTMLElement = document.createElement("cw-fatal-error");
        el.style.position = "absolute";
        el.style.width = "100%";
        el.style.height = "100%";
        el.style.zIndex = "1000001";
        el.innerHTML = `
            <div style=" width: 100%; height: 100%; display: flex; flex-direction: column; font-family: Arial; background-color: white;">
                <div style="margin: auto; text-align: center;">
                    <img src="https://panel.voluum.com/assets/503.png"/>
                    <h1 style="margin-top: 35px;">503 Error! We’re under maintenance!</h1>
                    <div style="max-width: 550px; margin: 0 auto 20px; font-size: 14px;">Do not worry, this has no effect on our traffic servers and your campaigns will continue to run as always. Try again in a few minutes by refreshing your browser.</div>
                    <a href="/" style="width: 165px; height: 28px; border-radius: 3px; background-color: #2e4887; color: white; text-decoration: none; padding: 6px 9px; font-size: 12px; font-weight: bold;">
                        Take me back to Voluum!
                    </a>
                </div>
            </div>
        `;
        document.querySelector("body")?.appendChild(el);
    }
}
