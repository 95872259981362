import { Observable, Observer } from "rxjs";

export class Ajax {
    public static get(target: string, options?: IOptions): Promise<unknown> {
        return Ajax.request(
            Object.assign({ url: target, method: "GET" }, options)
        ).toPromise();
    }

    public static request(options: IOptions): Observable<unknown> {
        return new Observable((observer: Observer<unknown>): void => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.addEventListener(
                "readystatechange",
                function onReadyStateChange(): void {
                    if (
                        xhr.readyState === XMLHttpRequest.DONE ||
                        xhr.readyState === XMLHttpRequest.UNSENT
                    ) {
                        if (xhr.status !== 200) {
                            observer.error({
                                xhr,
                                code: xhr.status,
                                status: xhr.statusText,
                            });
                        } else {
                            observer.next(
                                xhr.responseText
                                    ? JSON.parse(xhr.responseText)
                                    : {}
                            );
                        }
                        observer.complete();
                        xhr.removeEventListener(
                            "readystatechange",
                            onReadyStateChange
                        );
                    }
                }
            );
            xhr.open(options.method, options.url, true);
            if (options.headers) {
                for (const headerName in options.headers) {
                    if (options.headers.hasOwnProperty(headerName)) {
                        xhr.setRequestHeader(
                            headerName,
                            options.headers[headerName]
                        );
                    }
                }
            }
            if (options.body) {
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.send(JSON.stringify(options.body));
            } else {
                xhr.send();
            }
        });
    }
}

export interface IOptions {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    headers?: { [header: string]: string };
    body?: object;
}
