export enum NotificationActionCommandType {
    NAVIGATION,
    EVENT,
}

export enum CustomNotificationEntityType {
    CAMPAIGN = "CAMPAIGN",
    DOMAIN = "DOMAIN",
    OFFER = "OFFER",
}
