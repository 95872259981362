var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { EntityId } from "@codewise/voluum-frontend-framework/repository";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
import { Feedback } from "./feedback.model";
import { PushNotification } from "./push-notification.model";
export class Message {
    get isFeedbacked() {
        return this.feedback instanceof Feedback;
    }
    get isNotSeen() {
        return this.seen == null;
    }
    get isNotRead() {
        return this.read == null;
    }
}
__decorate([
    EntityId(),
    AutoSerialize(),
    __metadata("design:type", String)
], Message.prototype, "id", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], Message.prototype, "created", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], Message.prototype, "seen", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof Date !== "undefined" && Date) === "function" ? _c : Object)
], Message.prototype, "read", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_d = typeof PushNotification !== "undefined" && PushNotification) === "function" ? _d : Object)
], Message.prototype, "payload", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_e = typeof Feedback !== "undefined" && Feedback) === "function" ? _e : Object)
], Message.prototype, "feedback", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], Message.prototype, "clientId", void 0);
