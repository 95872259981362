import { Serializer } from "@codewise/voluum-frontend-framework/repository";
import { Newsfeed } from "@voluum-panel/util/ng-free";
import { ajax } from "rxjs/ajax";
import { map, pluck } from "rxjs/operators";

export class NewsfeedService {
    public static load(): Promise<Newsfeed> {
        return ajax({
            url: "https://panel.voluum.com/newsfeed.json",
            crossDomain: true,
        })
            .pipe(
                pluck("response"),
                map((data: object) =>
                    Serializer.deserialize<Newsfeed>(Newsfeed, data)
                )
            )
            .toPromise() as Promise<Newsfeed>;
    }
}
