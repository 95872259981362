var User_1;
var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, Shared, SharedProperty, SharedPropertyObserver, SharedPropertyUpdateHook, } from "@codewise/voluum-frontend-framework/model";
import { BehaviorSubject, Observable } from "rxjs";
import { Membership } from "./membership.model";
let User = User_1 = class User {
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
    static deserializer(userData) {
        return new User_1(userData.id, userData.firstName, userData.lastName, userData.email, userData.role, userData.created, userData.state, userData.timezone, userData.currency, userData.defaultClientId, userData.memberships, userData.preferredClientId, userData.experimentalGroup);
    }
    constructor(id, firstName, lastName, email, role, creationTime, state, timeZone, currency, defaultClientId, memberships = [], preferredClientId, experimentalGroup) {
        this.role = role;
        this.creationTime = creationTime;
        this.state = state;
        this.isFirstLogin = false;
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName$ = new BehaviorSubject(this.fullName);
        this.email = email;
        this.email$ = new BehaviorSubject(this.email);
        this.timeZone = timeZone;
        this.timeZone$ = new BehaviorSubject(this.timeZone);
        this.currency = currency || "USD";
        this.currency$ = new BehaviorSubject(this.currency);
        this.memberships = memberships.map(Membership.deserializer);
        this.defaultClient = this.chooseDefaultClient(preferredClientId, defaultClientId);
        this.defaultClientMembership = this.memberships.find((membership) => membership.client === this.defaultClient);
        this.experimentalGroup = experimentalGroup;
        this.multiuserStatus$ = new BehaviorSubject(this.multiuserStatus);
    }
    isDemoAccount() {
        return this.role === "ROLE_DEMO";
    }
    isRoleOwner(clientId) {
        return this.memberships.some((membership) => membership.client.id === clientId && membership.role === "OWNER");
    }
    onSharedPropertyUpdate(property) {
        switch (property) {
            case "firstName":
            case "lastName":
                this.fullName$.next(this.fullName);
                break;
        }
    }
    chooseDefaultClient(preferredClientId, defaultClientId) {
        let defaultClient;
        const clients = this.memberships.map((member) => member.client);
        defaultClient = clients.find((client) => client.id === preferredClientId);
        if (!defaultClient) {
            defaultClient = clients.find((client) => client.id === defaultClientId);
        }
        return defaultClient;
    }
};
__decorate([
    ReferentiallyImmutableKey(),
    __metadata("design:type", String)
], User.prototype, "id", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", Object)
], User.prototype, "multiuserStatus", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], User.prototype, "firstName", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], User.prototype, "lastName", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], User.prototype, "email", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], User.prototype, "currency", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", Boolean)
], User.prototype, "experimentalGroup", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], User.prototype, "timeZone", void 0);
__decorate([
    SharedPropertyObserver("multiuserStatus"),
    __metadata("design:type", typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object)
], User.prototype, "multiuserStatus$", void 0);
__decorate([
    SharedPropertyObserver("currency"),
    __metadata("design:type", typeof (_b = typeof Observable !== "undefined" && Observable) === "function" ? _b : Object)
], User.prototype, "currency$", void 0);
__decorate([
    SharedPropertyObserver("timeZone"),
    __metadata("design:type", typeof (_c = typeof Observable !== "undefined" && Observable) === "function" ? _c : Object)
], User.prototype, "timeZone$", void 0);
__decorate([
    SharedPropertyObserver("email"),
    __metadata("design:type", typeof (_d = typeof Observable !== "undefined" && Observable) === "function" ? _d : Object)
], User.prototype, "email$", void 0);
__decorate([
    SharedPropertyUpdateHook(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], User.prototype, "onSharedPropertyUpdate", null);
User = User_1 = __decorate([
    ReferentiallyImmutable(),
    Shared("User"),
    __metadata("design:paramtypes", [String, String, String, String, String, String, String, String, String, String, Array, String, Boolean])
], User);
export { User };
