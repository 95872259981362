import { IWorkspacesManager, Workspace } from "@voluum-panel/profile/ng-free";
import { BehaviorSubject } from "rxjs";

import { EventBus } from "../../../service/event_bus";
import { IResponse } from "../../../service/startup";

export class WorkspacesManager implements IWorkspacesManager {
    public workspaces$: BehaviorSubject<Workspace[]> = new BehaviorSubject([]);
    public selectedWorkspaces$: BehaviorSubject<Workspace[]> =
        new BehaviorSubject([]);
    public selectedWorkspaces: Workspace[] = [];
    public workspaces: Workspace[] = [];

    constructor(private eventBus: EventBus) {
        this.eventBus.onUpdateWorkspacesList.subscribe((data) => {
            this.onUpdateWorkspacesList(data);
        });
    }

    public parseResponseAndAssignWorkspaces(
        response: IResponse<IWorkspaceResponse>
    ): void {
        /**
         * workspaces - all workspaces available for user
         * selectedWorkspaces - workspaces selected in toolbar
         */
        if (response.status !== 200) {
            return;
        }
        this.workspaces = response.body.workspaces
            .map((ws) => new Workspace(ws.id, ws.name))
            .sort(this.sortWorkspacesByName);
        this.workspaces$ = new BehaviorSubject<Workspace[]>(this.workspaces);
        this.selectedWorkspaces$ = new BehaviorSubject<Workspace[]>(
            this.workspaces
        );

        /**
         * At first we check if there are any selected workspaces ids saved in URL
         * If yes, then we assign them to this.selectedWorkspaces
         * If no, we mark all available workspaces as selected
         */
        if (
            location.hash.includes("ws=") &&
            location.hash.split("ws=")[1] !== ""
        ) {
            const selectedWorkspacesIds: string[] = location.hash
                .split("ws=")
                .splice(1)
                .map((id) => id.slice(0, 36));
            this.selectedWorkspaces = this.workspaces.filter((ws) =>
                selectedWorkspacesIds.includes(ws.id as string)
            );
            if (!this.selectedWorkspaces.length) {
                this.selectedWorkspaces = this.workspaces;
            }
        } else {
            this.selectedWorkspaces = this.workspaces;
        }

        /**
         * Send data to TRK
         * Propagate data to forms & setup
         */
        this.updateWorkspaces(this.workspaces, this.selectedWorkspaces);
    }

    public updateWorkspaces(
        workspaces: Workspace[],
        selectedWorkspaces: Workspace[]
    ): void {
        this.selectedWorkspaces = selectedWorkspaces;
        this.selectedWorkspaces$.next(this.selectedWorkspaces);
        this.workspaces = workspaces;
        this.workspaces$.next(this.workspaces);
    }

    public onUpdateWorkspacesList(newWorkspacesList: Workspace[]): void {
        let newWorkspace: Workspace | undefined;
        this.workspaces = this.workspaces.map(
            (ws) => new Workspace(ws.id, ws.name)
        );
        newWorkspacesList = newWorkspacesList.map(
            (ws) => new Workspace(ws.id, ws.name)
        );
        // in case of editing workspace:
        if (this.workspaces.length === newWorkspacesList.length) {
            if (this.workspaces.length === this.selectedWorkspaces.length) {
                this.selectedWorkspaces = newWorkspacesList;
            }
        } else {
            // new workspace added:
            newWorkspace = newWorkspacesList.find(
                (ws) => !this.workspaces.includes(ws)
            );
            if (newWorkspace) {
                this.selectedWorkspaces.push(newWorkspace);
            }
        }
        this.updateWorkspaces(newWorkspacesList, this.selectedWorkspaces);
    }

    private sortWorkspacesByName(
        workspaceA: Workspace,
        workspaceB: Workspace
    ): number {
        const workspaceAName = workspaceA.name?.toLowerCase() ?? "";
        const workspaceBName = workspaceB.name?.toLowerCase() ?? "";

        if (workspaceAName < workspaceBName) {
            return -1;
        } else if (workspaceAName > workspaceBName) {
            return 1;
        } else {
            return 0;
        }
    }
}

export interface IWorkspaceResponse {
    workspaces: Workspace[];
}
