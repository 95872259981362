var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize, AutoSerializeCollection, } from "@codewise/voluum-frontend-framework/repository";
import { ColumnModel } from "./column.model";
import { ConversionRegistrationTimeEnum } from "./enum/conversion-registration-time.enum";
import { HomeScreenEnum } from "./enum/home-screen.enum";
import { MainScreenEnum } from "./enum/main-screen.enum";
import { ReportOpeningWayEnum } from "./enum/report-opening-way.enum";
import { StatusFilterEnum } from "./enum/status-filter.enum";
import { OrderedColumnsConfigurationModel } from "./ordered-columns-configuration.model";
import { Direction, SortModel } from "./sort.model";
export class ReportPreferenceModel {
    constructor() {
        this.conversionRegistrationTime = ConversionRegistrationTimeEnum.CONVERSION;
        this.homeScreen = HomeScreenEnum.DASHBOARD_CAMPAIGN;
        this.mainScreen = MainScreenEnum.TRACKER;
        this.reportOpeningWay = ReportOpeningWayEnum.BOTH_OPTIONS;
        this.limit = 100;
        this.filter = StatusFilterEnum.ACTIVE;
        this.columns = [];
        this.sort = {
            columnName: "",
            direction: Direction.ASC,
        };
    }
}
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_a = typeof ConversionRegistrationTimeEnum !== "undefined" && ConversionRegistrationTimeEnum) === "function" ? _a : Object)
], ReportPreferenceModel.prototype, "conversionRegistrationTime", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_b = typeof HomeScreenEnum !== "undefined" && HomeScreenEnum) === "function" ? _b : Object)
], ReportPreferenceModel.prototype, "homeScreen", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_c = typeof MainScreenEnum !== "undefined" && MainScreenEnum) === "function" ? _c : Object)
], ReportPreferenceModel.prototype, "mainScreen", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_d = typeof ReportOpeningWayEnum !== "undefined" && ReportOpeningWayEnum) === "function" ? _d : Object)
], ReportPreferenceModel.prototype, "reportOpeningWay", void 0);
__decorate([
    AutoSerializeCollection(OrderedColumnsConfigurationModel),
    __metadata("design:type", Array)
], ReportPreferenceModel.prototype, "orderedColumnsConfiguration", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], ReportPreferenceModel.prototype, "limit", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_e = typeof StatusFilterEnum !== "undefined" && StatusFilterEnum) === "function" ? _e : Object)
], ReportPreferenceModel.prototype, "filter", void 0);
__decorate([
    AutoSerializeCollection(ColumnModel),
    __metadata("design:type", Array)
], ReportPreferenceModel.prototype, "columns", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_f = typeof SortModel !== "undefined" && SortModel) === "function" ? _f : Object)
], ReportPreferenceModel.prototype, "sort", void 0);
