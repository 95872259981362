import { NotificationSubtypeEnum, } from "@codewise/voluum-frontend-core/events";
import { HttpStatus } from "@voluum-panel/util/ng-free";
import { filter, map, scan, share } from "rxjs/operators";
export class HttpRequestErrorHandler {
    constructor(eventBus) {
        this.eventBus = eventBus;
        this.httpErrorMessage$ = this.eventBus.onHttpRequestError.pipe(map(convertToHttpErrorMessage), share());
        this.unauthorizedRequest$ = this.httpErrorMessage$.pipe(filter((error) => error.status === HttpStatus.UNAUTHORIZED));
    }
    init() {
        this.httpErrorMessage$
            .pipe(filter(isHandledError), map((error) => createNotification(error)), scan((previousNotification, currentNotification) => {
            this.eventBus.hideToastNotification(previousNotification);
            return currentNotification;
        }))
            .subscribe((notification) => this.eventBus.showToastNotification(notification));
    }
}
const NOT_HANDLED_ERROR_STATUSES = [
    HttpStatus.BAD_REQUEST,
    HttpStatus.UNAUTHORIZED,
    HttpStatus.DEMO_ACCOUNT,
    HttpStatus.EXPECTATION_FAILED,
    HttpStatus.CONFLICT,
    HttpStatus.FOUND,
];
const NOT_HANDLED_ERROR_CODES = ["LOCKED"];
const CUSTOM_MESSAGES = [
    {
        test: (msg) => msg.status === HttpStatus.REQUESTED_RANGE_NOT_SATISFIABLE,
        getMessage: () => "Time range passed is too long.",
    },
    {
        test: (msg) => msg.status === HttpStatus.INSUFFICIENT_SUBSCRIPTION,
        getMessage: () => "You are not authorized to perform requested action on this resource.",
    },
    {
        test: (msg) => msg.status === HttpStatus.TOO_MANY_REQUESTS,
        getMessage: () => "It appears you are trying to send too many requests at once. Please, wait a moment between requests.",
    },
    {
        test: (msg) => msg.status === HttpStatus.ABORTED && navigator.onLine === false,
        getMessage: () => "Your internet connection timed out during your request. Please, refresh your page.",
    },
    {
        test: (msg) => msg.status === HttpStatus.ABORTED && isAdBlockOn(),
        getMessage: () => "The HTTP request can't be resolved. It seems you are running AdBlock which could be " +
            "the reason for this issue. Please, disable AdBlock for this page and try again.",
    },
    {
        test: (msg) => msg.errorCode === "QUOTA_EXCEEDED",
        getMessage: () => "You have reached the limit of active items of this type. Please, contact the support to increase the limit.",
    },
    {
        test: () => true,
        getMessage: () => "A front end error has occurred. Please, try again in a moment. " +
            "Don't worry, your campaigns will continue to run as always.",
    },
];
function createNotification(error) {
    return {
        subType: NotificationSubtypeEnum.ERROR,
        message: generateErrorMessage(error),
        closeDelay: 10000,
    };
}
function generateErrorMessage(error) {
    var _a;
    return (((_a = CUSTOM_MESSAGES.find((customMessage) => customMessage.test(error))) === null || _a === void 0 ? void 0 : _a.getMessage()) || "Unknown error has occurred.");
}
function isHandledError(error) {
    const shouldIgnoreErrorBasedOnStatus = NOT_HANDLED_ERROR_STATUSES.includes(error.status) === true;
    const shouldIgnoreErrorBasedOnErrorCode = NOT_HANDLED_ERROR_CODES.includes(error.errorCode) === true;
    return !(shouldIgnoreErrorBasedOnStatus || shouldIgnoreErrorBasedOnErrorCode);
}
function convertToHttpErrorMessage(response) {
    const status = response.hasOwnProperty("status")
        ? response.status
        : response.statusCode;
    let errorCode = "";
    let body = undefined;
    const url = response.url;
    if (response.error && response.error.error) {
        errorCode = response.error.error.code;
        body = response.error.error;
    }
    return { status, errorCode, body, url };
}
function isAdBlockOn() {
    const testAdBlockElement = document.createElement("div");
    testAdBlockElement.innerHTML = "&nbsp;";
    testAdBlockElement.className = "adsbox";
    document.body.appendChild(testAdBlockElement);
    const _isAdBlockOn = testAdBlockElement.offsetHeight === 0;
    document.body.removeChild(testAdBlockElement);
    return _isAdBlockOn;
}
