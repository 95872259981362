import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, } from "@codewise/voluum-frontend-framework/model";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
let CustomConversion = class CustomConversion {
    toString() {
        return `${this.index} - ${this.name}`;
    }
    constructor(index, name, sendPostbackToTrafficSource, includeInCost) {
        this.index = index;
        if (name) {
            this.name = name;
        }
        if (sendPostbackToTrafficSource !== undefined) {
            this.sendPostbackToTrafficSource = sendPostbackToTrafficSource;
        }
        if (includeInCost !== undefined) {
            this.includeInCost = includeInCost;
        }
    }
};
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], CustomConversion.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], CustomConversion.prototype, "sendPostbackToTrafficSource", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], CustomConversion.prototype, "includeInCost", void 0);
__decorate([
    AutoSerialize(),
    ReferentiallyImmutableKey(),
    __metadata("design:type", Number)
], CustomConversion.prototype, "index", void 0);
CustomConversion = __decorate([
    ReferentiallyImmutable(),
    __metadata("design:paramtypes", [Number, String, Boolean, Boolean])
], CustomConversion);
export { CustomConversion };
