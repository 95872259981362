var Client_1;
var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, Shared, SharedProperty, SharedPropertyObserver, } from "@codewise/voluum-frontend-framework/model";
import { BehaviorSubject, Observable } from "rxjs";
let Client = Client_1 = class Client {
    static deserializer(clientObject) {
        return new Client_1(clientObject.id, clientObject.name, clientObject.role, clientObject.toggles, clientObject.features, clientObject.referrerToken, clientObject.phoneNumber, clientObject.created, clientObject.initialPlan);
    }
    constructor(id, name, role, toggles = [], features, referrerToken, phoneNumber, created, initialPlan) {
        this.role = role;
        this.features = features;
        this.referrerToken = referrerToken;
        this.created = created;
        this.initialPlan = initialPlan;
        this.id = id;
        this.name = name;
        this.name$ = new BehaviorSubject(this.name);
        this.toggles = toggles;
        if (new Date(created) > new Date("2019-01-09T00:00:00.000Z")) {
            this.toggles.push("basic-campaign-form");
        }
        this.toggles$ = new BehaviorSubject(this.toggles);
        this.phoneNumber = phoneNumber;
        this.phoneNumber$ = new BehaviorSubject(this.phoneNumber);
    }
    toString() {
        return this.name;
    }
    hasToggle(name) {
        return this.toggles.includes(name);
    }
    appendToggles(toggles) {
        this.toggles = Array.from(new Set(this.toggles.concat(toggles)));
        return this.toggles;
    }
    hasTrackingFeature() {
        return this.hasFeature("AFFILIATE_TRACKING");
    }
    hasFeature(feature) {
        return this.features.indexOf(feature) > -1;
    }
};
__decorate([
    ReferentiallyImmutableKey(),
    __metadata("design:type", String)
], Client.prototype, "id", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], Client.prototype, "name", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", String)
], Client.prototype, "phoneNumber", void 0);
__decorate([
    SharedProperty(),
    __metadata("design:type", Array)
], Client.prototype, "toggles", void 0);
__decorate([
    SharedPropertyObserver("toggles"),
    __metadata("design:type", typeof (_b = typeof Observable !== "undefined" && Observable) === "function" ? _b : Object)
], Client.prototype, "toggles$", void 0);
__decorate([
    SharedPropertyObserver("name"),
    __metadata("design:type", typeof (_c = typeof Observable !== "undefined" && Observable) === "function" ? _c : Object)
], Client.prototype, "name$", void 0);
__decorate([
    SharedPropertyObserver("phoneNumber"),
    __metadata("design:type", typeof (_d = typeof Observable !== "undefined" && Observable) === "function" ? _d : Object)
], Client.prototype, "phoneNumber$", void 0);
Client = Client_1 = __decorate([
    Shared("Client"),
    ReferentiallyImmutable(),
    __metadata("design:paramtypes", [String, String, Object, Array, Array, String, String, typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object, String])
], Client);
export { Client };
