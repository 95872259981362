const defaultCookieOptions = {
    domain: window.location.hostname,
};

interface CookiesJs {
    get: (name: string) => string;
    set: (name: string, value: string, options: ICookieOptions) => string;
    expire: (name: string, options: ICookieOptions) => string;
}

export class Cookie {
    private static service: CookiesJs = require("cookies-js");

    public static get(name: string): string {
        return Cookie.service.get(name);
    }

    public static set(
        name: string,
        value: string,
        cookieOptions: ICookieOptions = defaultCookieOptions
    ): string {
        return Cookie.service.set(name, value, cookieOptions);
    }

    public static delete(
        name: string,
        cookieOptions: ICookieOptions = defaultCookieOptions
    ): string {
        return Cookie.service.expire(name, cookieOptions);
    }
}

interface ICookieOptions {
    path?: string;
    domain?: string;
    expires?: Date;
}
