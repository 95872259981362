import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
    Shared,
    SharedProperty,
    SharedPropertyObserver,
} from "@codewise/voluum-frontend-framework/model";
import { BehaviorSubject, Observable } from "rxjs";

import type { MembershipRole } from "../../user/model/membership-role.type";
import { ClientFeature } from "./client-feature.enum";
import { FeatureToggle } from "./feature-toggle.type";

@Shared("Client")
@ReferentiallyImmutable()
export class Client {
    @ReferentiallyImmutableKey()
    public readonly id: string;

    @SharedProperty()
    public name: string;

    @SharedProperty()
    public phoneNumber: string;

    @SharedProperty()
    public toggles: FeatureToggle[];

    @SharedPropertyObserver("toggles")
    public toggles$: Observable<FeatureToggle[]>;

    @SharedPropertyObserver("name")
    public name$: Observable<string>;

    @SharedPropertyObserver("phoneNumber")
    public phoneNumber$: Observable<string>;

    public static deserializer(clientObject: IClientJSON): Client {
        return new Client(
            clientObject.id,
            clientObject.name,
            clientObject.role,
            clientObject.toggles,
            clientObject.features,
            clientObject.referrerToken,
            clientObject.phoneNumber,
            clientObject.created,
            clientObject.initialPlan
        );
    }

    constructor(
        id: string,
        name: string,
        public readonly role: MembershipRole,
        toggles: FeatureToggle[] = [],
        private readonly features: ClientFeature[],
        public readonly referrerToken: string,
        phoneNumber: string,
        public readonly created: Date,
        public readonly initialPlan: string
    ) {
        this.id = id;
        this.name = name;
        this.name$ = new BehaviorSubject<string>(this.name);
        this.toggles = toggles;
        // let's not talk about it... date-based logic for toggles was removed from LD integration on BE side
        // to be removed in ~2-4 weeks when form is available to everyone, reminder has been set :)
        if (new Date(created) > new Date("2019-01-09T00:00:00.000Z")) {
            this.toggles.push("basic-campaign-form");
        }
        this.toggles$ = new BehaviorSubject<FeatureToggle[]>(this.toggles);
        this.phoneNumber = phoneNumber;
        this.phoneNumber$ = new BehaviorSubject<string>(this.phoneNumber);
    }

    public toString(): string {
        return this.name;
    }

    // INFO: added overloaded method with string param to allow to define local toggles
    public hasToggle(name: FeatureToggle): boolean;
    public hasToggle(name: string): boolean;

    public hasToggle(name: FeatureToggle): boolean {
        return this.toggles.includes(name);
    }

    public appendToggles(toggles: string[]): FeatureToggle[] {
        this.toggles = Array.from(
            new Set(this.toggles.concat(toggles as FeatureToggle[]))
        );
        return this.toggles;
    }

    public hasTrackingFeature(): boolean {
        return this.hasFeature("AFFILIATE_TRACKING");
    }

    private hasFeature(feature: ClientFeature): boolean {
        return this.features.indexOf(feature) > -1;
    }
}

export interface IClientJSON {
    id: string;
    name: string;
    role: MembershipRole;
    toggles: FeatureToggle[];
    features: ClientFeature[];
    referrerToken: string;
    phoneNumber: string;
    created: Date;
    initialPlan: string;
}
