import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable6ReportColumn {
    CUSTOM_VARIABLE_6 = "customVariable6",
    CUSTOM_VARIABLE_6_HASH = "customVariable6Hash",
    CUSTOM_VARIABLE_6_MARKER = "customVariable6Marker",
}

export type CustomVariable6ReportColumns =
    | CustomVariable6ReportColumn
    | CustomVariableReportColumn;
