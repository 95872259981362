import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
export class Feedback {
    static useful() {
        return new Feedback(Feedback.USEFUL_SCORE);
    }
    static notUseful() {
        return new Feedback(Feedback.NOT_USEFUL_SCORE);
    }
    get isUseful() {
        return this.score === Feedback.USEFUL_SCORE;
    }
    get isNotUseful() {
        return this.score === Feedback.NOT_USEFUL_SCORE;
    }
    constructor(score) {
        this.score = score;
    }
}
Feedback.USEFUL_SCORE = 5;
Feedback.NOT_USEFUL_SCORE = 1;
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], Feedback.prototype, "score", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], Feedback.prototype, "comment", void 0);
