// re-export models and data that is used in tests and ring (to prevent being dependent on Angular)
export { FeatureFlag } from "../../src/lib/client/model/feature-flag.enum";
export { type FeatureToggle } from "../../src/lib/client/model/feature-toggle.type";
export { Client } from "../../src/lib/client/model/client.model";
export {
    type IClientBasicInfoJson,
    ClientBasicInfoModel,
} from "../../src/lib/client/model/client-basic-info.model";

export * from "../../src/lib/columns-order/model";
export {
    Coupons,
    type ICouponsJson,
} from "../../src/lib/coupons/model/coupons.model";

export { type IPlanUpgradedEvent } from "../../src/lib/events";
export { type IOpenFullPaymentFlowEvent } from "../../src/lib/events/open-full-payment-flow.event";
export { type IAuthenticationTokenChangedEvent } from "../../src/lib/events/authentication-token-changed.event";
export { type IChangeClientEvent } from "../../src/lib/events/change-client.event";
export { type ILoginFailureEvent } from "../../src/lib/events/login-failure.event";
export { type ILoginSuccessfulEvent } from "../../src/lib/events//login-successful.event";
export { type IReloginEvent } from "../../src/lib/events//relogin.event";
export { type IReloginWithGoogleEvent } from "../../src/lib/events/relogin-with-google.event";
export { type IReloginWithMfaEvent } from "../../src/lib/events/relogin-with-mfa.event";
export { type IShowLoginFormEvent } from "../../src/lib/events/show-login-form.event";
export { type IShowMFAFormEvent } from "../../src/lib/events/show-mfa-form.event";
export { type ISignOutEvent } from "../../src/lib/events/sign-out.event";
export { type IStartPollingPlanDataEvent } from "../../src/lib/events/start-polling-plan-data.event";
export { type IUpdateWorkspacesListEvent } from "../../src/lib/events/update-workspaces-list.event";

export { Login } from "../../src/lib/login/model/login.model";
export { type LoginWithGoogle } from "../../src/lib/login/model/login-with-google.model";
export { type LoginWithMfa } from "../../src/lib/login/model/login-with-mfa.model";

export { BillingInfo } from "../../src/lib/payment/model/billing-info.model";
export { PaymentNotification } from "../../src/lib/payment/model/payment-notification.model";

export { Plan, type IPlanJson } from "../../src/lib/plan/model/plan.model";
export { QuotaLimits } from "../../src/lib/plan/model/quota-limits.model";

export { Session } from "../../src/lib/session/model/session.model";

export { type IUserJSON, User } from "../../src/lib/user/model/user.model";
export { MultiuserMigration } from "../../src/lib/user/model/multiuser-migration.model";
export { type MultiuserMigrationStatus } from "../../src/lib/user/model/multiuser-migration-status.type";
export {
    Membership,
    type IMembershipJSON,
} from "../../src/lib/user/model/membership.model";

export { UserPreferenceModel } from "../../src/lib/user-preference/model/user-preference.model";

export { type IWorkspacesManager } from "../../src/lib/workspaces/model/workspaces-manager.interface";
export { Workspace } from "../../src/lib/workspaces/model/workspace.model";
