import { __awaiter } from "tslib";
import { EventName } from "@codewise/voluum-frontend-core/events";
import { zip } from "rxjs";
import { bufferCount, take } from "rxjs/operators";
import { Apps } from "./apps";
import { Cookie } from "./cookie";
import { Invitation } from "./invitation";
const PLAN_CODE_TO_REVIEW = "cw-plan-code-to-review";
export class AppLoadStrategyService {
    static getAppLoadStrategy() {
        return AppLoadStrategyService.isFirstLoad() ? "FIRST_LOAD" : "ALL";
    }
    static isFirstLoadApp(appName) {
        return (["voluumFrontendForms", "voluumFrontendToolbar"].indexOf(appName) >
            -1);
    }
    static isFirstLoad() {
        return (window.location.hash.indexOf("firstLogin=true") > -1 &&
            !Cookie.get(Invitation.INVITATION_COOKIE_NAME) &&
            !Cookie.get(PLAN_CODE_TO_REVIEW));
    }
    static manageFirstLoad(appService) {
        if (!appService.eventBusService ||
            !appService.appConfig ||
            !appService.startupResponse) {
            return;
        }
        const eventBusService = appService.eventBusService;
        const appConfig = appService.appConfig;
        eventBusService.updateDataLayer({
            event: "GAEvent",
            eventCategory: "User",
            eventAction: "FirstLoginAfterRegistration",
            eventLabel: "",
        });
        eventBusService.dispatch({
            eventName: EventName.OPEN_FULL_PAYMENT_FLOW,
            data: null,
        });
        eventBusService.onLoadRestAppsIfNeeded.subscribe(() => __awaiter(this, void 0, void 0, function* () {
            eventBusService.showLoadingBar();
            yield zip(...Apps.load(appConfig, "FIRST_LOAD_REST")).toPromise();
            Apps.instantiate(appConfig, "FIRST_LOAD_REST").forEach((app) => appService.bootstrapApp(app));
            eventBusService.onReady
                .pipe(bufferCount(Apps.getActiveApps(appConfig, "FIRST_LOAD_REST").length), take(1))
                .subscribe(() => {
                eventBusService.hideLoadingBar();
            });
        }));
    }
}
