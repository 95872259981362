import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { EventName } from "@codewise/voluum-frontend-core/events";
import { IOpenFullPaymentFlowEvent } from "@voluum-panel/profile/ng-free";
import { zip } from "rxjs";
import { bufferCount, take } from "rxjs/operators";

import App from "../app";
import { Apps } from "./apps";
import { Cookie } from "./cookie";
import { EventBus } from "./event_bus";
import { Invitation } from "./invitation";

const PLAN_CODE_TO_REVIEW = "cw-plan-code-to-review";

export type IAppLoadStrategy = "FIRST_LOAD" | "FIRST_LOAD_REST" | "ALL";

export class AppLoadStrategyService {
    public static getAppLoadStrategy(): IAppLoadStrategy {
        return AppLoadStrategyService.isFirstLoad() ? "FIRST_LOAD" : "ALL";
    }

    public static isFirstLoadApp(appName: string): boolean {
        return (
            ["voluumFrontendForms", "voluumFrontendToolbar"].indexOf(appName) >
            -1
        );
    }

    public static isFirstLoad(): boolean {
        return (
            window.location.hash.indexOf("firstLogin=true") > -1 &&
            !Cookie.get(Invitation.INVITATION_COOKIE_NAME) &&
            !Cookie.get(PLAN_CODE_TO_REVIEW)
        );
    }

    public static manageFirstLoad(appService: App): void {
        if (
            !appService.eventBusService ||
            !appService.appConfig ||
            !appService.startupResponse
        ) {
            return;
        }
        const eventBusService: EventBus = appService.eventBusService;
        const appConfig: IAppConfig = appService.appConfig;

        // All first login events stuff
        eventBusService.updateDataLayer({
            event: "GAEvent",
            eventCategory: "User",
            eventAction: "FirstLoginAfterRegistration",
            eventLabel: "",
        });

        // Show payment flow
        eventBusService.dispatch<IOpenFullPaymentFlowEvent>({
            eventName: EventName.OPEN_FULL_PAYMENT_FLOW,
            data: null,
        });

        // Listen to payment flow end to load rest apps
        eventBusService.onLoadRestAppsIfNeeded.subscribe(async () => {
            eventBusService.showLoadingBar();

            // Load rest apps
            await zip(...Apps.load(appConfig, "FIRST_LOAD_REST")).toPromise();

            // Instantiate them
            Apps.instantiate(appConfig, "FIRST_LOAD_REST").forEach((app) =>
                appService.bootstrapApp(app)
            );

            eventBusService.onReady
                .pipe(
                    bufferCount(
                        Apps.getActiveApps(appConfig, "FIRST_LOAD_REST").length
                    ),
                    take(1)
                )
                .subscribe(() => {
                    eventBusService.hideLoadingBar();
                });
        });
    }
}
