import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

export class BillingAddress {
    @AutoSerialize()
    public addressLine!: string;

    @AutoSerialize()
    public city!: string;

    @AutoSerialize()
    public country!: string;

    @AutoSerialize()
    public zipCode!: string;
}
