var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
import { PaymentNotificationEnum } from "./payment-notification.enum";
export class PaymentNotification {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], PaymentNotification.prototype, "balance", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_a = typeof PaymentNotificationEnum !== "undefined" && PaymentNotificationEnum) === "function" ? _a : Object)
], PaymentNotification.prototype, "notification", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], PaymentNotification.prototype, "paymentDeadLine", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], PaymentNotification.prototype, "owningPaymentProfile", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], PaymentNotification.prototype, "losingCustomDomains", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], PaymentNotification.prototype, "losingUserAccounts", void 0);
