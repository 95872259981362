import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

export class Config {
    public static getAppConfig(): Promise<IAppConfig> {
        return ajax
            .get("/app.config.json")
            .pipe(pluck("response"))
            .toPromise() as Promise<IAppConfig>;
    }
}
