import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";

export class DocumentationLinks {
    public static load(): Promise<unknown> {
        return ajax({
            url: "https://panel.voluum.com/doc.mapping.json",
            crossDomain: true,
        })
            .pipe(pluck("response"))
            .toPromise() as Promise<unknown>;
    }
}
