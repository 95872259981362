import { ContactWidget } from "@codewise/freshdesk-contact-widget";
import { NotificationSubtypeEnum } from "@codewise/voluum-frontend-core/events";
class Freshdesk {
    constructor(eventBus, session, appConfig) {
        eventBus.onOpenSupportPopup.subscribe((event) => {
            const eventData = event.data;
            const emailConfig = session.user.isDemoAccount()
                ? { email: "", email_disabled: false }
                : { email: session.user.email, email_disabled: true };
            const data = Object.assign(Object.assign({}, emailConfig), { subject: eventData.data.subject, subject_disabled: !!eventData.data.subject, link: eventData.data.link, feedback: eventData.data.feedback, message_placeholder: eventData.data.messagePlaceholder, message: eventData.data.message, message_disabled: eventData.data.messageDisabled, additional_message: eventData.data.additionalMessage, show_tip: eventData.data.showTip });
            const tags = eventData.freshdesk.tags || [];
            if (!tags.includes("send-to-sales")) {
                tags.push("send-to-cs");
            }
            if (appConfig.environment !== "prod") {
                tags.push("TEST");
            }
            const freshdesk = {
                api_key: eventData.freshdesk.apiKey,
                api_pass: eventData.freshdesk.apiPass,
                email_config_id: eventData.freshdesk.emailConfigId,
                group_id: eventData.freshdesk.groupId,
                priority: eventData.freshdesk.priority,
                product_id: eventData.freshdesk.productId,
                source: eventData.freshdesk.sourceId,
                status: eventData.freshdesk.status,
                tags: tags,
                authorized: true,
            };
            ContactWidget.show(data, freshdesk).then(() => {
                var _a;
                eventBus.showToastNotification({
                    subType: NotificationSubtypeEnum.SUCCESS,
                    message: "Thanks for getting in touch with the Voluum Team.",
                    closeDelay: 10000,
                });
                (_a = event.confirm) === null || _a === void 0 ? void 0 : _a.call(event);
            }, () => {
                var _a;
                (_a = event.deny) === null || _a === void 0 ? void 0 : _a.call(event);
            });
        });
    }
}
export { Freshdesk };
