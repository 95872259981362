import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable1ReportColumn {
    CUSTOM_VARIABLE_1 = "customVariable1",
    CUSTOM_VARIABLE_1_HASH = "customVariable1Hash",
    CUSTOM_VARIABLE_1_MARKER = "customVariable1Marker",
    WANDUUM_RELATIVE_ROI = "wanduumRelativeRoi",
    WANDUUM_RELATIVE_CV = "wanduumRelativeCv",
}

export type CustomVariable1ReportColumns =
    | CustomVariable1ReportColumn
    | CustomVariableReportColumn;
