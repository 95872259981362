import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable9ReportColumn {
    CUSTOM_VARIABLE_9 = "customVariable9",
    CUSTOM_VARIABLE_9_HASH = "customVariable9Hash",
    CUSTOM_VARIABLE_9_MARKER = "customVariable9Marker",
}

export type CustomVariable9ReportColumns =
    | CustomVariable9ReportColumn
    | CustomVariableReportColumn;
