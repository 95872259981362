var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
export class PromoSummary {
    constructor() {
        this.hubspotLists = [];
        this.hubspotProperties = {};
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], PromoSummary.prototype, "hubspotLists", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => Object,
    }),
    __metadata("design:type", typeof (_a = typeof Record !== "undefined" && Record) === "function" ? _a : Object)
], PromoSummary.prototype, "hubspotProperties", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => Object,
    }),
    __metadata("design:type", Object)
], PromoSummary.prototype, "promoResource", void 0);
