import { Ajax } from "./ajax";
import { Navigation } from "./navigation";
export class ZpToken {
    constructor(appConfig, url) {
        this.appConfig = appConfig;
        this.url = url;
    }
    get getZPToken() {
        return this.url.get(ZpToken.ZP_TOKEN_QUERY_PARAM_NAME);
    }
    hasZPToken() {
        return (typeof this.getZPToken === "string" && this.getZPToken.length > 0);
    }
    handleZPToken() {
        if (this.hasZPToken()) {
            Ajax.get(`${this.appConfig.server.portal}/profile/token?token=${this.getZPToken}`).then((response) => this.onUserDataRequestSuccess(response), (response) => this.onUserDataRequestError(response));
        }
    }
    onUserDataRequestSuccess(response) {
        if (this.hasZPToken()) {
            Navigation.builder()
                .withUrl(this.appConfig.frontend.registerFinal)
                .withQuery({
                firstName: response.firstName,
                lastName: response.lastName,
                email: response.email,
                zpToken: this.getZPToken,
            })
                .assign();
        }
    }
    onUserDataRequestError(_) {
        Navigation.builder()
            .withUrl(this.appConfig.frontend.login)
            .withHash("?reason=settingPasswordFailed")
            .assign();
    }
}
ZpToken.ZP_TOKEN_QUERY_PARAM_NAME = "pwdSetToken";
