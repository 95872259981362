export enum OfferReportColumn {
    OFFER_ID = "offerId",
    OFFER_NAME = "offerName",
    OFFER_URL = "offerUrl",
    OFFER_NOTES = "offerNotes",
    OFFER_COUNTRY = "offerCountry",
    OFFER_WORKSPACE_NAME = "offerWorkspaceName",
    OFFER_WORKSPACE_ID = "offerWorkspaceId",
    OFFER_PAYOUT = "payout",
    OFFER_CLICK_CAP_VALUE = "clickCapValue",
    OFFER_CONVERSION_CAP_VALUE = "conversionCapValue",
    OFFER_MARKER = "offerIdMarker",
    OFFER_PAYOUT_IN_ORIGINAL_CURRENCY = "offerPayoutInOriginalCurrency",
    OFFER_PAYOUT_CURRENCY = "offerPayoutCurrency",
    OFFER_TAGS = "offerTags",
}
