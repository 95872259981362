export enum CustomColumnFormat {
    MONETARY = "MONETARY",
    PERCENTAGE = "PERCENTAGE",
    DECIMAL = "DECIMAL",
}

export const customColumnsFormatMapping: Map<CustomColumnFormat, string> =
    new Map([
        [CustomColumnFormat.DECIMAL, "Decimal"],
        [CustomColumnFormat.MONETARY, "Monetary"],
        [CustomColumnFormat.PERCENTAGE, "Percentage"],
    ]);
