export enum MessageCategory {
    PAYMENT_FAILED = "PAYMENT_FAILED",
    PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED",
    OVERAGES_STARTED = "OVERAGES_STARTED",
    OVERAGES_HUGE = "OVERAGES_HUGE",
    OFFER_CAP_NEARLY_REACHED = "OFFER_CAP_NEARLY_REACHED",
    OFFER_CAP_REACHED = "OFFER_CAP_REACHED",
    OFFER_STOPPED_CONVERTING = "OFFER_STOPPED_CONVERTING",
    CAMPAIGN_FIRST_CONVERSION = "CAMPAIGN_FIRST_CONVERSION",
    CAMPAIGN_ROI_BELOW_ZERO = "CAMPAIGN_ROI_BELOW_ZERO",
    NOTIFICATION_SEEN = "NOTIFICATION_SEEN",
    CUSTOM_NOTIFICATION = "CUSTOM_NOTIFICATION",
    ANNUAL_PLAN_PROMO = "ANNUAL_PLAN_PROMO",
}
