import {
    AutoSerialize,
    AutoSerializeCollection,
} from "@codewise/voluum-frontend-framework/serialization";

export class NewsfeedItem {
    @AutoSerialize()
    public title: string | undefined;
    @AutoSerialize()
    public description: string | undefined;
    @AutoSerialize()
    public date: string | undefined;
    @AutoSerialize()
    public docLink: string | undefined;
    @AutoSerialize()
    public isNew: boolean | undefined;
    @AutoSerialize()
    public cta: string | undefined;
}

export class Newsfeed {
    @AutoSerializeCollection(NewsfeedItem)
    public newsfeedData: NewsfeedItem[] = [];
    @AutoSerialize()
    public timestamp: string | undefined;
}
