import { IAppConfig } from "@codewise/voluum-frontend-core/app";

import { Ajax } from "./ajax";
import { Cookie } from "./cookie";
import { Navigation } from "./navigation";
import { IResponse } from "./startup";
import { Url } from "./url";

interface IInvitationData {
    id: string;
    invitedEmailAddress: string;
    invitedUserExists: boolean;
    invitingClient: {
        id: string;
        name: string;
    };
    invitingUser: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

export class Invitation {
    public static INVITATION_QUERY_PARAM_NAME: string = "invitationId";

    public static INVITATION_COOKIE_NAME: string = "cw-invitation-id";

    constructor(
        private appConfig: IAppConfig,
        private url: Url
    ) {}

    get invitationToken(): string | undefined {
        return this.url.get(Invitation.INVITATION_QUERY_PARAM_NAME);
    }

    public handleInvitationWhenUserNotLoggedIn(): void {
        if (this.invitationToken) {
            Ajax.get(
                `${this.appConfig.server.portal}/multiuser/invitation/${this.invitationToken}`
            ).then(
                (response: IInvitationData) =>
                    this.onInvitationRequestSuccess(response),
                () => this.onInvitationRequestError()
            );
        }
    }

    public handleInvitationWhenUserLoggedIn(
        invitationResponse: IResponse<IInvitation>
    ): void {
        if (invitationResponse.status !== 200) {
            return this.onInvitationRequestError();
        }

        if (this.invitationToken) {
            Cookie.set(Invitation.INVITATION_COOKIE_NAME, this.invitationToken);
        }
    }

    private onInvitationRequestSuccess(response: IInvitationData): void {
        let target: string;
        if (response.invitedUserExists && this.invitationToken) {
            Cookie.set(Invitation.INVITATION_COOKIE_NAME, this.invitationToken);
            target = this.appConfig.frontend.login;
        } else {
            target = this.appConfig.frontend.registerFinal;
        }

        if (this.invitationToken) {
            Navigation.builder()
                .withUrl(target)
                .withQuery({
                    email: response.invitedEmailAddress,
                    companyName: response.invitingClient.name,
                    ownerFirstName: response.invitingUser.firstName,
                    ownerLastName: response.invitingUser.lastName,
                    invitationId: this.invitationToken,
                })
                .assign();
        }
    }

    private onInvitationRequestError(): void {
        Navigation.builder()
            .withUrl(this.appConfig.frontend.login)
            .withHash("?reason=invitationAcceptingFailed")
            .assign();
    }
}

export interface IInvitation {
    invitationToken: string;
}
