var _a, _b;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize, Serializer, } from "@codewise/voluum-frontend-framework/repository";
import { Length, NestedValidation, Required, } from "@codewise/voluum-frontend-framework/validation";
import { ColumnExpression } from "./custom-column-expression.model";
import { CustomColumnFormat } from "./custom-column-format.model";
export class CustomColumn {
    constructor() {
        this.label = "";
        this.colored = false;
        this.visible = true;
        this.expression = new ColumnExpression();
        this.format = CustomColumnFormat.DECIMAL;
    }
    get snapshot() {
        return JSON.stringify(Serializer.serialize(this));
    }
    clone() {
        return Serializer.deserialize(CustomColumn, Serializer.serialize(this));
    }
}
__decorate([
    Required({
        propertyName: "Column name",
    }),
    Length({
        min: 2,
        max: 40,
        propertyName: "Column name",
    }),
    AutoSerialize(),
    __metadata("design:type", String)
], CustomColumn.prototype, "label", void 0);
__decorate([
    Required(),
    AutoSerialize(),
    __metadata("design:type", String)
], CustomColumn.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], CustomColumn.prototype, "colored", void 0);
__decorate([
    NestedValidation(),
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof ColumnExpression !== "undefined" && ColumnExpression) === "function" ? _a : Object)
], CustomColumn.prototype, "expression", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => String,
    }),
    __metadata("design:type", typeof (_b = typeof CustomColumnFormat !== "undefined" && CustomColumnFormat) === "function" ? _b : Object)
], CustomColumn.prototype, "format", void 0);
