import { EventName, } from "@codewise/voluum-frontend-core/events";
import { filter, map } from "rxjs/operators";
export class EventBus {
    constructor(eventBusClient) {
        this.eventBusClient = eventBusClient;
    }
    get onClientChange() {
        return this.obtain(EventName.CHANGE_CLIENT);
    }
    get onActivateRoute() {
        return this.obtain(EventName.ACTIVATE_ROUTE);
    }
    get onHttpRequestError() {
        return this.obtain(EventName.HTTP_REQUEST_ERROR);
    }
    get onRelogin() {
        return this.obtain(EventName.RELOGIN);
    }
    get onReloginWithMfa() {
        return this.obtain(EventName.RELOGIN_WITH_MFA);
    }
    get onReloginWithGoogle() {
        return this.obtain(EventName.RELOGIN_WITH_GOOGLE);
    }
    get onUpdateDataLayer() {
        return this.obtain(EventName.UPDATE_DATA_LAYER);
    }
    get onReady() {
        return this.obtain(EventName.MICRO_APP_READY);
    }
    get onAllReady() {
        return this.obtain(EventName.MICRO_APP_ALL_READY);
    }
    get onSignOut() {
        return this.obtain(EventName.SIGN_OUT);
    }
    get onUpdateWorkspacesList() {
        return this.obtain(EventName.UPDATE_WORKSPACES_LIST);
    }
    get onStartPollingPlanData() {
        return this.obtain(EventName.START_POLLING_PLAN_DATA);
    }
    get onOpenSupportPopup() {
        return this.obtainConfirmable(EventName.OPEN_SUPPORT_POPUP);
    }
    get onPerformNotificationAction() {
        return this.obtain(EventName.PERFORM_NOTIFICATION_ACTION);
    }
    get onLoadRestAppsIfNeeded() {
        return this.obtain(EventName.LOAD_REST_APPS_IF_NEEDED);
    }
    showHttpRequestError(error) {
        this.dispatch({
            eventName: EventName.HTTP_REQUEST_ERROR,
            data: error,
        });
    }
    requestMFACode(credentialsData) {
        this.dispatch({
            eventName: EventName.SHOW_MFA_FORM,
            data: credentialsData,
        });
    }
    showLoginForm() {
        this.dispatch({
            eventName: EventName.SHOW_LOGIN_FORM,
        });
    }
    loginSuccessful() {
        this.dispatch({
            eventName: EventName.LOGIN_SUCCESSFUL,
        });
    }
    loginFailure() {
        this.dispatch({
            eventName: EventName.LOGIN_FAILURE,
        });
    }
    authenticationTokenChanged(token) {
        this.eventBusClient.send("authenticationTokenChanged", token);
        this.dispatch({
            eventName: EventName.AUTHENTICATION_TOKEN_CHANGED,
            data: token,
        });
    }
    activateRoute(routeName) {
        this.dispatch({
            eventName: EventName.ROUTE_ACTIVATED,
            data: routeName,
        });
    }
    updateDataLayer(data) {
        this.dispatch({
            eventName: EventName.UPDATE_DATA_LAYER,
            data,
        });
    }
    showLoadingBar() {
        this.dispatch({
            eventName: EventName.SHOW_LOADING,
        });
    }
    hideLoadingBar() {
        this.dispatch({
            eventName: EventName.HIDE_LOADING,
        });
    }
    showToastNotification(notification) {
        this.dispatch({
            eventName: EventName.SHOW_TOAST_NOTIFICATION,
            data: notification,
        });
    }
    hideToastNotification(notification) {
        this.dispatch({
            eventName: EventName.HIDE_TOAST_NOTIFICATION,
            data: notification,
        });
    }
    allReady() {
        this.dispatch({
            eventName: EventName.MICRO_APP_ALL_READY,
        });
    }
    sendEvent(eventName, eventData) {
        return this.eventBusClient.send(eventName, eventData);
    }
    dispatch(event) {
        var _a;
        this.eventBusClient.send(event.eventName, (_a = event["data"]) !== null && _a !== void 0 ? _a : null, false);
    }
    obtain(eventName) {
        return this.eventBusClient.receive.pipe(filter((event) => event.eventName === eventName), map((event) => { var _a; return (_a = event.data) !== null && _a !== void 0 ? _a : null; }));
    }
    obtainConfirmable(eventName) {
        return this.eventBusClient.receive.pipe(filter((event) => event.eventName === eventName));
    }
}
