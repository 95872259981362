import { __decorate, __metadata } from "tslib";
import { EntityDeserializer, EntitySerializer, } from "@codewise/voluum-frontend-framework/repository";
export class UserPreferenceBookmarksModel {
    constructor(bookmarks = []) {
        this.list = bookmarks;
    }
    static deserializer(transfer) {
        const userPreferenceBookmarks = new UserPreferenceBookmarksModel();
        if (!!transfer && Object.keys(transfer).length > 0) {
            for (const transferKey of Object.keys(transfer)) {
                userPreferenceBookmarks.list.push(new UserPreferenceBookmarkModel(transferKey, transfer[transferKey]));
            }
        }
        return userPreferenceBookmarks;
    }
    serialize() {
        const transfer = {};
        for (const bookmark of this.list) {
            transfer[bookmark.name] = bookmark.url;
        }
        return transfer;
    }
    isEmpty() {
        return this.list.length === 0;
    }
}
__decorate([
    EntitySerializer(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], UserPreferenceBookmarksModel.prototype, "serialize", null);
__decorate([
    EntityDeserializer(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", UserPreferenceBookmarksModel)
], UserPreferenceBookmarksModel, "deserializer", null);
export class UserPreferenceBookmarkModel {
    constructor(name = "", url = "") {
        this.name = name;
        this.url = url;
    }
    equals(compareWith) {
        if (!compareWith) {
            return false;
        }
        if (compareWith === this) {
            return true;
        }
        return this.name === compareWith.name && this.url === compareWith.url;
    }
}
