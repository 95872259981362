var _a;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
import { BillingAddress } from "./billing-address.model";
export class BillingInfo {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingInfo.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], BillingInfo.prototype, "taxId", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof BillingAddress !== "undefined" && BillingAddress) === "function" ? _a : Object)
], BillingInfo.prototype, "address", void 0);
