export enum HttpStatus {
    ABORTED = 0,
    FOUND = 302,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    INSUFFICIENT_SUBSCRIPTION = 402,
    FORBIDDEN = 403,
    CONFLICT = 409,
    REQUESTED_RANGE_NOT_SATISFIABLE = 416,
    EXPECTATION_FAILED = 417,
    DEMO_ACCOUNT = 418,
    TOO_MANY_REQUESTS = 429,
}
