import { BehaviorSubject } from "rxjs";

import { BillingType } from "./billing-type.enum";
import { QuotaLimits } from "./quota-limits.model";

export class Plan {
    public code$: BehaviorSubject<string>;
    public maximumUserAccounts$: BehaviorSubject<number>;
    public maximumDrillDownLevel$: BehaviorSubject<number>;
    public adminWorkerUserLimit$: BehaviorSubject<number>;
    public memberUserLimit$: BehaviorSubject<number>;
    public customDomains$: BehaviorSubject<number>;
    public longTermContract$: BehaviorSubject<boolean>;
    public longTermBillingCycles$: BehaviorSubject<number>;
    public longTermAmount$: BehaviorSubject<number>;
    public includedEvents$: BehaviorSubject<number>;
    public availableRedirectModes$: BehaviorSubject<string[]>;
    public availableCampaignRules$: BehaviorSubject<string[]>;
    public apiAccessRestricted$: BehaviorSubject<boolean>;
    public dataRetentionDays$: BehaviorSubject<number>;
    public billingType$: BehaviorSubject<BillingType>;

    public static deserializer(planObject: IPlanJson): Plan {
        return new Plan(
            planObject.code,
            planObject.adminWorkerUserLimit,
            planObject.memberUserLimit,
            planObject.customDomains,
            planObject.longTermContract,
            planObject.longTermBillingCycles,
            planObject.longTermAmount,
            planObject.includedEvents,
            planObject.availableRedirectModes,
            planObject.availableConditionTypes,
            planObject.apiAccessRestricted,
            planObject.entityQuota,
            planObject.maximumUserAccounts,
            planObject.maximumDrillDownLevel,
            planObject.dataRetentionDays,
            planObject.billingType
        );
    }

    constructor(
        public code: string,
        public adminWorkerUserLimit: number,
        public memberUserLimit: number,
        public customDomains: number,
        public longTermContract: boolean,
        public longTermBillingCycles: number,
        public longTermAmount: number,
        public includedEvents: number,
        public availableRedirectModes: string[],
        public availableCampaignRules: string[],
        public apiAccessRestricted: boolean,
        public entityQuota: QuotaLimits,
        public maximumUserAccounts: number,
        public maximumDrillDownLevel: number,
        public dataRetentionDays: number,
        public billingType: BillingType
    ) {
        this.code$ = new BehaviorSubject<string>(this.code);
        this.dataRetentionDays$ = new BehaviorSubject<number>(
            this.dataRetentionDays
        );
        this.maximumUserAccounts$ = new BehaviorSubject<number>(
            this.maximumUserAccounts
        );
        this.maximumDrillDownLevel$ = new BehaviorSubject<number>(
            this.maximumDrillDownLevel
        );
        this.adminWorkerUserLimit$ = new BehaviorSubject<number>(
            this.entityQuota && this.entityQuota.adminWorkerUserLimit
                ? this.entityQuota.adminWorkerUserLimit
                : this.adminWorkerUserLimit
        );
        this.memberUserLimit$ = new BehaviorSubject<number>(
            this.entityQuota && this.entityQuota.memberUserLimit
                ? this.entityQuota.memberUserLimit
                : this.memberUserLimit
        );
        this.customDomains$ = new BehaviorSubject<number>(
            this.entityQuota && this.entityQuota.customDomains
                ? this.entityQuota.customDomains
                : this.customDomains
        );
        this.longTermContract$ = new BehaviorSubject<boolean>(
            this.longTermContract
        );
        this.longTermBillingCycles$ = new BehaviorSubject<number>(
            this.longTermBillingCycles
        );
        this.longTermAmount$ = new BehaviorSubject<number>(this.longTermAmount);
        this.includedEvents$ = new BehaviorSubject<number>(this.includedEvents);
        this.availableRedirectModes$ = new BehaviorSubject<string[]>(
            this.availableRedirectModes
        );
        this.availableCampaignRules$ = new BehaviorSubject<string[]>(
            this.availableCampaignRules
        );
        this.apiAccessRestricted$ = new BehaviorSubject<boolean>(
            this.apiAccessRestricted
        );
        this.billingType$ = new BehaviorSubject<BillingType>(this.billingType);
    }

    public updatePlanData(newPlanData: IPlanJson): void {
        this.code = newPlanData.code;
        this.code$.next(newPlanData.code);

        this.maximumUserAccounts = newPlanData.maximumUserAccounts;
        this.maximumUserAccounts$.next(newPlanData.maximumUserAccounts);

        this.maximumDrillDownLevel = newPlanData.maximumDrillDownLevel;
        this.maximumDrillDownLevel$.next(newPlanData.maximumDrillDownLevel);

        this.memberUserLimit = newPlanData.memberUserLimit;
        this.memberUserLimit$.next(newPlanData.memberUserLimit);

        this.adminWorkerUserLimit = newPlanData.adminWorkerUserLimit;
        this.adminWorkerUserLimit$.next(newPlanData.adminWorkerUserLimit);

        this.customDomains = newPlanData.customDomains;
        this.customDomains$.next(newPlanData.customDomains);

        this.longTermContract = newPlanData.longTermContract;
        this.longTermContract$.next(newPlanData.longTermContract);

        this.longTermBillingCycles = newPlanData.longTermBillingCycles;
        this.longTermBillingCycles$.next(newPlanData.longTermBillingCycles);

        this.longTermAmount = newPlanData.longTermAmount;
        this.longTermAmount$.next(newPlanData.longTermAmount);

        this.includedEvents = newPlanData.includedEvents;
        this.includedEvents$.next(newPlanData.includedEvents);

        this.availableRedirectModes = newPlanData.availableRedirectModes;
        this.availableRedirectModes$.next(newPlanData.availableRedirectModes);

        this.availableCampaignRules = newPlanData.availableConditionTypes;
        this.availableCampaignRules$.next(newPlanData.availableConditionTypes);

        this.apiAccessRestricted = newPlanData.apiAccessRestricted;
        this.apiAccessRestricted$.next(newPlanData.apiAccessRestricted);

        this.customDomains =
            newPlanData.entityQuota && newPlanData.entityQuota.customDomains
                ? newPlanData.entityQuota.customDomains
                : newPlanData.customDomains;
        this.customDomains$.next(this.customDomains);
        this.billingType$.next(newPlanData.billingType);
    }
}

export interface IPlanJson {
    id: string;
    code: string;
    name: string;
    price: number;
    billingCycle: string;
    includedEvents: number;
    costPerEvent: number;
    dataRetentionDays: number;
    adminWorkerUserLimit: number;
    memberUserLimit: number;
    customDomains: number;
    longTermContract: boolean;
    longTermBillingCycles: number;
    longTermAmount: number;
    availableRedirectModes: string[];
    availableConditionTypes: string[];
    apiAccessRestricted: boolean;
    entityQuota: QuotaLimits;
    maximumUserAccounts: number;
    maximumDrillDownLevel: number;
    billingType: BillingType;
}
