import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
} from "@codewise/voluum-frontend-framework/model";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

@ReferentiallyImmutable()
export class CustomConversion {
    @AutoSerialize()
    public name?: string;

    @AutoSerialize()
    public sendPostbackToTrafficSource?: boolean;

    @AutoSerialize()
    public includeInCost?: boolean;

    @AutoSerialize()
    @ReferentiallyImmutableKey()
    public index: number;

    public toString(): string {
        return `${this.index} - ${this.name}`;
    }

    constructor(
        index: number,
        name?: string,
        sendPostbackToTrafficSource?: boolean,
        includeInCost?: boolean
    ) {
        this.index = index;
        if (name) {
            this.name = name;
        }
        if (sendPostbackToTrafficSource !== undefined) {
            this.sendPostbackToTrafficSource = sendPostbackToTrafficSource;
        }
        if (includeInCost !== undefined) {
            this.includeInCost = includeInCost;
        }
    }
}
