import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";

import { ColumnsOrderTemplate } from "../../columns-order/model";

/**
 * @see UserPreferenceModel
 */
export class OrderedColumnsConfigurationModel {
    @AutoSerialize()
    public key: string[] = [];

    @AutoSerialize()
    public value: string[] = [];

    @AutoSerialize()
    public active!: boolean;

    @AutoSerialize()
    public frozen: string[] = [];

    @AutoSerialize()
    public columnOrderTemplateId?: ColumnsOrderTemplate;
}
