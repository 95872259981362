export class ClientBasicInfoModel {
    public static deserializer(
        data: IClientBasicInfoJson
    ): ClientBasicInfoModel {
        return new ClientBasicInfoModel(data.id, data.creationTime);
    }

    constructor(
        private readonly _id: string,
        private readonly _creationTime: string
    ) {}

    get id(): string {
        return this._id;
    }

    get creationTime(): string {
        return this._creationTime;
    }
}

export interface IClientBasicInfoJson {
    id: string;
    creationTime: string;
}
