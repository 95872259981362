import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable4ReportColumn {
    CUSTOM_VARIABLE_4 = "customVariable4",
    CUSTOM_VARIABLE_4_HASH = "customVariable4Hash",
    CUSTOM_VARIABLE_4_MARKER = "customVariable4Marker",
}

export type CustomVariable4ReportColumns =
    | CustomVariable4ReportColumn
    | CustomVariableReportColumn;
