import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
export class ColumnModel {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], ColumnModel.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], ColumnModel.prototype, "visible", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], ColumnModel.prototype, "precision", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], ColumnModel.prototype, "width", void 0);
