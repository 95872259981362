import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
export class QuotaLimits {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "affiliateNetworks", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "asyncReportsRequests", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "autoRules", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "campaigns", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "columnConditionsPerAutoRule", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "dimensionLists", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "entitiesPerAutoRule", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "flows", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "ipRangesPerCondition", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "ipUaEntriesPerRule", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "ipUaRules", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "landers", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "offers", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "sharedReports", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "trafficLogDailyRequests", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "trafficSources", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "valuesPerDimensionList", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "workspaces", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "customDomains", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "adminWorkerUserLimit", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "memberUserLimit", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Number)
], QuotaLimits.prototype, "customDomainCertificates", void 0);
