import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { datadogRum, RumEvent } from "@datadog/browser-rum";
import { Session } from "@voluum-panel/profile/ng-free";

import { Cookie } from "./cookie";

export class Datadog {
    private static readonly DATADOG_COOKIE: string = "_dd_s";
    private readonly isDatadogAvailable: boolean =
        this.appConfig.hasOwnProperty("datadog");
    private readonly releaseVersion: string = Object.values(
        this.appConfig.appVersions
    ).join("-");
    private ensuredDatadogCookieIsUpToDate: boolean = false;

    constructor(private appConfig: IAppConfig) {
        if (this.isDatadogAvailable) {
            this.ensureDatadogFromLoginNotLeak();

            datadogRum.init({
                applicationId: this.appConfig.datadog.applicationId,
                clientToken: this.appConfig.datadog.clientToken,
                site: "datadoghq.com",
                service: "voluum-panel",
                env: this.appConfig.environment,
                // Specify a version number to identify the deployed version of your application in Datadog
                version: this.releaseVersion,
                sessionSampleRate: this.appConfig.datadog.sessionSampleRate,
                sessionReplaySampleRate:
                    this.appConfig.datadog.sessionReplaySampleRate,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                trackViewsManually: true,
                defaultPrivacyLevel: "mask-user-input",
                allowedTracingUrls: [
                    this.appConfig.server.portal,
                    this.appConfig.server.api,
                ],
                beforeSend: (event: RumEvent) => {
                    // We need to ensure here if datadog cookie is still up-to-date
                    // Before we could not be sure that cookie is already set because of datadog set cookie in non synchronized way
                    if (!this.ensuredDatadogCookieIsUpToDate) {
                        this.ensuredDatadogCookieIsUpToDate = true;

                        this.ensureDatadogCookieIsUpToDate();
                    }

                    // Discard CSP errors, we don't want to track them via RUM
                    // It makes analyzing of users sessions harder
                    // We will track them via Datadog Logs
                    if (
                        event.type === "error" &&
                        event.error.source === "report"
                    ) {
                        return false;
                    }

                    // Discard errors from the browser extension
                    if (
                        event.type === "error" &&
                        event.error.stack?.includes("chrome-extension://")
                    ) {
                        return false;
                    }

                    if (
                        event.type === "error" &&
                        event.error.stack?.includes("safari-extension:")
                    ) {
                        return false;
                    }

                    // Discard errors from the Userflow
                    if (
                        event.type === "error" &&
                        event.error.stack?.includes("UserflowError")
                    ) {
                        // To simplify potential debugging
                        console.warn(
                            "Ignoring following Userflow error",
                            event.error.stack
                        );

                        return false;
                    }

                    // Discard error from failed chunk loading, because we could not do anything about it
                    if (
                        event.type === "error" &&
                        event.error.stack?.includes("ChunkLoadError")
                    ) {
                        console.warn(
                            "Ignoring following ChunkLoadError",
                            event.error.stack
                        );

                        return false;
                    }

                    // Discard unidentified error that occurs only for 3 users
                    // It is probably caused by some browser extension, and it makes a lot of noise in our Datadog
                    // In replays we can see no impact on these 3 users, so we can safely ignore it
                    if (
                        event.type === "error" &&
                        event.error.stack?.includes(
                            "RangeError: Maximum call stack size exceeded"
                        ) &&
                        event.error.stack?.includes("varProxy")
                    ) {
                        return false;
                    }

                    return true;
                },
            });

            // Add tags
            datadogRum.setGlobalContextProperty("app_versions", {
                ...this.appConfig.appVersions,
            });
        }
    }

    public setUserContext(session: Session): void {
        if (this.isDatadogAvailable) {
            datadogRum.setUser({
                id: session.currentClient.id,
                // Copy of above just for clear information in DD which ID is which
                clientId: session.currentClient.id,
                userId: session.user.id,
            });
        }
    }

    public addError(error: unknown, context?: object | undefined): void {
        if (this.isDatadogAvailable) {
            datadogRum.addError(error, context);
        }
    }

    // Function ensures that we will have panel with clean Datadog instance
    // Prevents leaking of cookie from login page to panel
    // It causes for example that session sampling overwrites panel sampling
    private ensureDatadogFromLoginNotLeak(): void {
        const queryParams = new URLSearchParams(
            window.location.hash.split("?")[1]
        );
        const login = queryParams.get("login");

        // Clean datadog cookie after each login
        if (login === "success") {
            Cookie.delete(Datadog.DATADOG_COOKIE, {});
        }
    }

    // Function ensured that DD cookie name is still up-to-date
    // Otherwise leaking will return
    // If that error occurs, update dd cookie from https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#rum-cookies
    // Otherwise turn of DD in login, by removing datadogLogin object from appConfig
    private ensureDatadogCookieIsUpToDate(): void {
        if (!Cookie.get(Datadog.DATADOG_COOKIE)) {
            this.addError(
                new Error(
                    `Datadog cookie "${Datadog.DATADOG_COOKIE}" probably changed its name. Please investigate.`
                )
            );

            // For development purposes
            console.error(
                `Datadog cookie "${Datadog.DATADOG_COOKIE}" probably changed its name. Please investigate.`
            );
        }
    }
}
