import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable2ReportColumn {
    CUSTOM_VARIABLE_2 = "customVariable2",
    CUSTOM_VARIABLE_2_HASH = "customVariable2Hash",
    CUSTOM_VARIABLE_2_MARKER = "customVariable2Marker",
}

export type CustomVariable2ReportColumns =
    | CustomVariable2ReportColumn
    | CustomVariableReportColumn;
