import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
export class SortModel {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], SortModel.prototype, "columnName", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], SortModel.prototype, "direction", void 0);
export var Direction;
(function (Direction) {
    Direction["ASC"] = "ASC";
    Direction["DESC"] = "DESC";
})(Direction || (Direction = {}));
