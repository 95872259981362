import { __awaiter } from "tslib";
import { IncomingMessagesRepository } from "../repository/incoming-messages";
import { NotificationActionDispatcher } from "./actions/dispatcher";
export class PushNotifications {
    constructor(appConfig, eventBus) {
        this.appConfig = appConfig;
        this.eventBus = eventBus;
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            this.incomingMessages = new IncomingMessagesRepository(this.appConfig, this.eventBus);
            new NotificationActionDispatcher(this.eventBus, window.location);
        });
    }
}
