var TimeZone_1;
import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, } from "@codewise/voluum-frontend-framework/model";
import { AutoSerialize, Serializer, } from "@codewise/voluum-frontend-framework/repository";
let TimeZone = TimeZone_1 = class TimeZone {
    toString() {
        return this.name;
    }
    clone() {
        return Serializer.deserialize(TimeZone_1, Serializer.serialize(this));
    }
};
__decorate([
    AutoSerialize(),
    ReferentiallyImmutableKey(),
    __metadata("design:type", String)
], TimeZone.prototype, "code", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], TimeZone.prototype, "name", void 0);
TimeZone = TimeZone_1 = __decorate([
    ReferentiallyImmutable()
], TimeZone);
export { TimeZone };
export const DefaultTimeZone = Serializer.deserialize(TimeZone, {
    code: "Etc/GMT",
    name: "(UTC) Coordinated Universal Time",
});
