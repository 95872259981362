import { EventName, NotificationSubtypeEnum, } from "@codewise/voluum-frontend-core/events";
import { empty, interval, noop } from "rxjs";
import { catchError, skipWhile, switchMap } from "rxjs/operators";
import { AUTHENTICATION_HEADER_NAME, AUTHENTICATION_PANEL_HEADER_NAME, } from "../../../globals";
import { Ajax } from "../../../service/ajax";
import { Authentication, encodeToken } from "../../../service/authentication";
const POLL_INTERVAL = 10 * 1000;
export class PlanMonitor {
    constructor(appConfig, eventBus, planManager, session) {
        this.appConfig = appConfig;
        this.eventBus = eventBus;
        this.planManager = planManager;
        this.session = session;
        this.eventBus.onStartPollingPlanData.subscribe((expectedPlanCode) => {
            this.expectedPlanCode = expectedPlanCode;
            if (!this.pollingSubscription) {
                this.startPollingPlan();
            }
        });
    }
    startPollingPlan() {
        this.pollingSubscription = interval(POLL_INTERVAL)
            .pipe(switchMap(() => this.getPlanData()), catchError(() => empty()), skipWhile((planData) => planData.code !== this.expectedPlanCode))
            .subscribe((planData) => {
            var _a;
            this.planManager.updatePlanData(planData);
            (_a = this.pollingSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            this.pollingSubscription = undefined;
            this.getFeatureToggles().subscribe((featuresResponse) => {
                this.session.currentClient.appendToggles(featuresResponse.features);
                this.eventBus.showToastNotification({
                    subType: NotificationSubtypeEnum.SUCCESS,
                    message: "Your plan has been successfully upgraded.",
                    closeDelay: 10000,
                });
                this.eventBus.dispatch({
                    eventName: EventName.PLAN_UPGRADED,
                    data: planData,
                });
            }, noop);
        });
    }
    getPlanData() {
        return Ajax.request({
            method: "GET",
            headers: {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(Authentication.getToken()),
            },
            url: `${this.appConfig.server.portal}/billing/plan`,
        });
    }
    getFeatureToggles() {
        return Ajax.request({
            method: "GET",
            headers: {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(Authentication.getToken()),
            },
            url: `${this.appConfig.server.portal}/billing/features`,
        });
    }
}
