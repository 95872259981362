import { datadogRum } from "@datadog/browser-rum";
import { Cookie } from "./cookie";
export class Datadog {
    constructor(appConfig) {
        this.appConfig = appConfig;
        this.isDatadogAvailable = this.appConfig.hasOwnProperty("datadog");
        this.releaseVersion = Object.values(this.appConfig.appVersions).join("-");
        this.ensuredDatadogCookieIsUpToDate = false;
        if (this.isDatadogAvailable) {
            this.ensureDatadogFromLoginNotLeak();
            datadogRum.init({
                applicationId: this.appConfig.datadog.applicationId,
                clientToken: this.appConfig.datadog.clientToken,
                site: "datadoghq.com",
                service: "voluum-panel",
                env: this.appConfig.environment,
                version: this.releaseVersion,
                sessionSampleRate: this.appConfig.datadog.sessionSampleRate,
                sessionReplaySampleRate: this.appConfig.datadog.sessionReplaySampleRate,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                trackViewsManually: true,
                defaultPrivacyLevel: "mask-user-input",
                allowedTracingUrls: [
                    this.appConfig.server.portal,
                    this.appConfig.server.api,
                ],
                beforeSend: (event) => {
                    var _a, _b, _c, _d, _e, _f;
                    if (!this.ensuredDatadogCookieIsUpToDate) {
                        this.ensuredDatadogCookieIsUpToDate = true;
                        this.ensureDatadogCookieIsUpToDate();
                    }
                    if (event.type === "error" &&
                        event.error.source === "report") {
                        return false;
                    }
                    if (event.type === "error" &&
                        ((_a = event.error.stack) === null || _a === void 0 ? void 0 : _a.includes("chrome-extension://"))) {
                        return false;
                    }
                    if (event.type === "error" &&
                        ((_b = event.error.stack) === null || _b === void 0 ? void 0 : _b.includes("safari-extension:"))) {
                        return false;
                    }
                    if (event.type === "error" &&
                        ((_c = event.error.stack) === null || _c === void 0 ? void 0 : _c.includes("UserflowError"))) {
                        console.warn("Ignoring following Userflow error", event.error.stack);
                        return false;
                    }
                    if (event.type === "error" &&
                        ((_d = event.error.stack) === null || _d === void 0 ? void 0 : _d.includes("ChunkLoadError"))) {
                        console.warn("Ignoring following ChunkLoadError", event.error.stack);
                        return false;
                    }
                    if (event.type === "error" &&
                        ((_e = event.error.stack) === null || _e === void 0 ? void 0 : _e.includes("RangeError: Maximum call stack size exceeded")) &&
                        ((_f = event.error.stack) === null || _f === void 0 ? void 0 : _f.includes("varProxy"))) {
                        return false;
                    }
                    return true;
                },
            });
            datadogRum.setGlobalContextProperty("app_versions", Object.assign({}, this.appConfig.appVersions));
        }
    }
    setUserContext(session) {
        if (this.isDatadogAvailable) {
            datadogRum.setUser({
                id: session.currentClient.id,
                clientId: session.currentClient.id,
                userId: session.user.id,
            });
        }
    }
    addError(error, context) {
        if (this.isDatadogAvailable) {
            datadogRum.addError(error, context);
        }
    }
    ensureDatadogFromLoginNotLeak() {
        const queryParams = new URLSearchParams(window.location.hash.split("?")[1]);
        const login = queryParams.get("login");
        if (login === "success") {
            Cookie.delete(Datadog.DATADOG_COOKIE, {});
        }
    }
    ensureDatadogCookieIsUpToDate() {
        if (!Cookie.get(Datadog.DATADOG_COOKIE)) {
            this.addError(new Error(`Datadog cookie "${Datadog.DATADOG_COOKIE}" probably changed its name. Please investigate.`));
            console.error(`Datadog cookie "${Datadog.DATADOG_COOKIE}" probably changed its name. Please investigate.`);
        }
    }
}
Datadog.DATADOG_COOKIE = "_dd_s";
