import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable10ReportColumn {
    CUSTOM_VARIABLE_10 = "customVariable10",
    CUSTOM_VARIABLE_10_HASH = "customVariable10Hash",
    CUSTOM_VARIABLE_10_MARKER = "customVariable10Marker",
}

export type CustomVariable10ReportColumns =
    | CustomVariable10ReportColumn
    | CustomVariableReportColumn;
