export interface ICouponsJson {
    coupons: ICouponJson[];
}

export interface ICouponJson {
    tag: string;
    code: string;
    activated: boolean;
    scope: string;
    originalAmount: number;
    amountLeft: number;
    percentOff: number;
    type: string;
    expirationInstant: string;
    validPeriod: string;
    activationDeadline: string;
    applicableForPlanCodes: string[];
}

export class Coupon {
    get tag(): string {
        return this._tag;
    }

    get activated(): boolean {
        return this._activated;
    }

    get scope(): string {
        return this._scope;
    }

    get originalAmount(): number {
        return this._originalAmount;
    }

    get amountLeft(): number {
        return this._amountLeft;
    }

    get percentOff(): number {
        return this._percentOff;
    }

    get type(): string {
        return this._type;
    }

    get expirationInstant(): string {
        return this._expirationInstant;
    }

    get code(): string {
        return this._code;
    }

    get applicableForPlanCodes(): string[] {
        return this._applicableForPlanCodes || [];
    }

    public static deserializer(data: ICouponJson): Coupon {
        return new Coupon(
            data.tag,
            data.code,
            data.activated,
            data.scope,
            data.originalAmount,
            data.amountLeft,
            data.percentOff,
            data.type,
            data.expirationInstant,
            data.activationDeadline,
            data.validPeriod,
            data.applicableForPlanCodes
        );
    }

    constructor(
        private _tag: string,
        private _code: string,
        private _activated: boolean,
        private _scope: string,
        private _originalAmount: number,
        private _amountLeft: number,
        private _percentOff: number,
        private _type: string,
        private _expirationInstant: string,
        public readonly activationDeadline: string,
        public readonly validPeriod: string,
        private _applicableForPlanCodes: string[]
    ) {}
}

export class Coupons {
    public static deserializer(couponsObject: ICouponsJson): Coupons {
        return new Coupons(couponsObject.coupons.map(Coupon.deserializer));
    }

    get coupons(): Coupon[] {
        return this._coupons;
    }

    constructor(private _coupons: Coupon[] = []) {}
}
