import { EntityId } from "@codewise/voluum-frontend-framework/repository";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

import { IMessage } from "./message.types";
import { MessageCategory } from "./message-category.enum";
import { MessageGroup } from "./message-group.enum";

export class PushNotification implements IMessage {
    @AutoSerialize()
    @EntityId()
    public tag!: string;

    @AutoSerialize()
    public body!: string;

    @AutoSerialize()
    public title!: string;

    @AutoSerialize({
        circuitBreaker: () => String,
    })
    public category!: MessageCategory;

    @AutoSerialize({
        circuitBreaker: () => String,
    })
    public group!: MessageGroup;

    @AutoSerialize()
    public clientId!: string;

    @AutoSerialize()
    public sendTime!: string;

    @AutoSerialize({
        circuitBreaker: () => Object,
    })
    public extras: { [key: string]: string } | undefined;
}
