import { bufferCount } from "rxjs/operators";
import { Apps } from "./apps";
export class EventWatcher {
    constructor(eventBus, appConfig, appLoadStrategy) {
        this.eventBus = eventBus;
        eventBus.onReady
            .pipe(bufferCount(Apps.getActiveApps(appConfig, appLoadStrategy).length))
            .subscribe(() => this.onAllReady());
    }
    onAllReady() {
        const readyTimestamp = Math.round(performance.now());
        if (window.performance) {
            this.eventBus.updateDataLayer({
                event: "GAEvent",
                eventCategory: "Timings",
                eventAction: "Load time",
                eventValue: readyTimestamp,
            });
        }
        const loader = document.querySelector("cw-loader");
        if (loader) {
            document.body.removeChild(loader);
        }
        document.body.classList.add(EventWatcher.APPS_READY_CLASS_NAME);
        this.eventBus.allReady();
    }
}
EventWatcher.APPS_READY_CLASS_NAME = "ready";
