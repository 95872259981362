var _a, _b;
import { __decorate, __metadata } from "tslib";
import { EntityId } from "@codewise/voluum-frontend-framework/repository";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
import { MessageCategory } from "./message-category.enum";
import { MessageGroup } from "./message-group.enum";
export class PushNotification {
}
__decorate([
    AutoSerialize(),
    EntityId(),
    __metadata("design:type", String)
], PushNotification.prototype, "tag", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], PushNotification.prototype, "body", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], PushNotification.prototype, "title", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => String,
    }),
    __metadata("design:type", typeof (_a = typeof MessageCategory !== "undefined" && MessageCategory) === "function" ? _a : Object)
], PushNotification.prototype, "category", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => String,
    }),
    __metadata("design:type", typeof (_b = typeof MessageGroup !== "undefined" && MessageGroup) === "function" ? _b : Object)
], PushNotification.prototype, "group", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], PushNotification.prototype, "clientId", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], PushNotification.prototype, "sendTime", void 0);
__decorate([
    AutoSerialize({
        circuitBreaker: () => Object,
    }),
    __metadata("design:type", Object)
], PushNotification.prototype, "extras", void 0);
