import { __decorate, __metadata } from "tslib";
import { Format, Required, } from "@codewise/voluum-frontend-framework/validation";
import { EMAIL_DOPPELGANG_REGEXP } from "../data/email-dopplegang.regexp";
export class Login {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
}
__decorate([
    Required(),
    Format({
        regexp: EMAIL_DOPPELGANG_REGEXP,
        formatName: "email",
    }),
    __metadata("design:type", String)
], Login.prototype, "email", void 0);
__decorate([
    Required(),
    __metadata("design:type", String)
], Login.prototype, "password", void 0);
