export enum LanderReportColumn {
    LANDER_ID = "landerId",
    LANDER_NAME = "landerName",
    LANDER_URL = "landerUrl",
    LANDER_NOTES = "landerNotes",
    LANDER_COUNTRY = "landerCountry",
    LANDER_NUMBER_OF_OFFERS = "numberOfOffers",
    LANDER_WORKSPACE_NAME = "landerWorkspaceName",
    LANDER_WORKSPACE_ID = "landerWorkspaceId",
    LANDER_TAGS = "landerTags",
}
