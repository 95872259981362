import {
    EventName,
    IActivateRouteEvent,
    IDatalessTypedConfirmableEvent,
    IDatalessTypedEvent,
    IHideLoadingEvent,
    IHideToastNotificationEvent,
    IHttpRequestErrorEvent,
    IMicroAppReadyEvent,
    IShowLoadingEvent,
    IShowToastNotificationEvent,
    IToastNotification,
    ITypedConfirmableEvent,
    ITypedEvent,
} from "@codewise/voluum-frontend-core/events";
import {
    EventBusClient,
    IEvent,
    ISendData,
} from "@codewise/voluum-frontend-framework/event_bus_client";
import {
    DataLayerPayload,
    IOpenSupportPopupEvent,
    IPerformNotificationActionEvent,
    IUpdateDataLayerEvent,
    NotificationAction,
} from "@voluum-panel/assistance/ng-free";
import {
    Client,
    IAuthenticationTokenChangedEvent,
    IChangeClientEvent,
    ILoginFailureEvent,
    ILoginSuccessfulEvent,
    IReloginEvent,
    IReloginWithGoogleEvent,
    IReloginWithMfaEvent,
    IShowLoginFormEvent,
    IShowMFAFormEvent,
    ISignOutEvent,
    IStartPollingPlanDataEvent,
    IUpdateWorkspacesListEvent,
    Login,
    LoginWithGoogle,
    LoginWithMfa,
    Workspace,
} from "@voluum-panel/profile/ng-free";
import {
    ILoadRestAppsIfNeededEvent,
    IMicroAppAllReadyEvent,
    IRouteActivatedEvent,
} from "@voluum-panel/util/ng-free";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

export class EventBus {
    constructor(private eventBusClient: EventBusClient) {}

    get onClientChange(): Observable<Client> {
        return this.obtain<IChangeClientEvent>(EventName.CHANGE_CLIENT);
    }

    get onActivateRoute(): Observable<string> {
        return this.obtain<IActivateRouteEvent>(EventName.ACTIVATE_ROUTE);
    }

    get onHttpRequestError(): Observable<Response> {
        return this.obtain<IHttpRequestErrorEvent>(
            EventName.HTTP_REQUEST_ERROR
        );
    }

    get onRelogin(): Observable<Login> {
        return this.obtain<IReloginEvent>(EventName.RELOGIN);
    }

    get onReloginWithMfa(): Observable<LoginWithMfa> {
        return this.obtain<IReloginWithMfaEvent>(EventName.RELOGIN_WITH_MFA);
    }

    get onReloginWithGoogle(): Observable<LoginWithGoogle> {
        return this.obtain<IReloginWithGoogleEvent>(
            EventName.RELOGIN_WITH_GOOGLE
        );
    }

    get onUpdateDataLayer(): Observable<DataLayerPayload> {
        return this.obtain<IUpdateDataLayerEvent>(EventName.UPDATE_DATA_LAYER);
    }

    get onReady(): Observable<null> {
        return this.obtain<IMicroAppReadyEvent>(EventName.MICRO_APP_READY);
    }

    get onAllReady(): Observable<null> {
        return this.obtain<IMicroAppAllReadyEvent>(
            EventName.MICRO_APP_ALL_READY
        );
    }

    get onSignOut(): Observable<null> {
        return this.obtain<ISignOutEvent>(EventName.SIGN_OUT);
    }

    get onUpdateWorkspacesList(): Observable<Workspace[]> {
        return this.obtain<IUpdateWorkspacesListEvent>(
            EventName.UPDATE_WORKSPACES_LIST
        );
    }

    get onStartPollingPlanData(): Observable<string> {
        return this.obtain<IStartPollingPlanDataEvent>(
            EventName.START_POLLING_PLAN_DATA
        );
    }

    get onOpenSupportPopup(): Observable<IOpenSupportPopupEvent> {
        return this.obtainConfirmable<IOpenSupportPopupEvent>(
            EventName.OPEN_SUPPORT_POPUP
        );
    }

    get onPerformNotificationAction(): Observable<NotificationAction> {
        return this.obtain<IPerformNotificationActionEvent>(
            EventName.PERFORM_NOTIFICATION_ACTION
        );
    }

    get onLoadRestAppsIfNeeded(): Observable<null> {
        return this.obtain<ILoadRestAppsIfNeededEvent>(
            EventName.LOAD_REST_APPS_IF_NEEDED
        );
    }

    /**
     * @deprecated
     * @see moved to shared
     */
    public showHttpRequestError(error: Response): void {
        this.dispatch<IHttpRequestErrorEvent>({
            eventName: EventName.HTTP_REQUEST_ERROR,
            data: error,
        });
    }

    public requestMFACode(credentialsData: LoginWithMfa): void {
        this.dispatch<IShowMFAFormEvent>({
            eventName: EventName.SHOW_MFA_FORM,
            data: credentialsData,
        });
    }

    public showLoginForm(): void {
        this.dispatch<IShowLoginFormEvent>({
            eventName: EventName.SHOW_LOGIN_FORM,
        });
    }

    public loginSuccessful(): void {
        this.dispatch<ILoginSuccessfulEvent>({
            eventName: EventName.LOGIN_SUCCESSFUL,
        });
    }

    public loginFailure(): void {
        this.dispatch<ILoginFailureEvent>({
            eventName: EventName.LOGIN_FAILURE,
        });
    }

    public authenticationTokenChanged(token: string): void {
        this.eventBusClient.send("authenticationTokenChanged", token);
        this.dispatch<IAuthenticationTokenChangedEvent>({
            eventName: EventName.AUTHENTICATION_TOKEN_CHANGED,
            data: token,
        });
    }

    public activateRoute(routeName: string): void {
        this.dispatch<IRouteActivatedEvent>({
            eventName: EventName.ROUTE_ACTIVATED,
            data: routeName,
        });
    }

    public updateDataLayer(data: DataLayerPayload): void {
        this.dispatch<IUpdateDataLayerEvent>({
            eventName: EventName.UPDATE_DATA_LAYER,
            data,
        });
    }

    public showLoadingBar(): void {
        this.dispatch<IShowLoadingEvent>({
            eventName: EventName.SHOW_LOADING,
        });
    }

    public hideLoadingBar(): void {
        this.dispatch<IHideLoadingEvent>({
            eventName: EventName.HIDE_LOADING,
        });
    }

    public showToastNotification(notification: IToastNotification): void {
        this.dispatch<IShowToastNotificationEvent>({
            eventName: EventName.SHOW_TOAST_NOTIFICATION,
            data: notification,
        });
    }

    public hideToastNotification(notification: IToastNotification): void {
        this.dispatch<IHideToastNotificationEvent>({
            eventName: EventName.HIDE_TOAST_NOTIFICATION,
            data: notification,
        });
    }

    public allReady(): void {
        this.dispatch<IMicroAppAllReadyEvent>({
            eventName: EventName.MICRO_APP_ALL_READY,
        });
    }

    /**
     * @deprecated
     * @see dispatch
     */
    public sendEvent(eventName: string, eventData: unknown): ISendData {
        return this.eventBusClient.send(eventName, eventData);
    }

    public dispatch<
        T extends (ITypedEvent | IDatalessTypedEvent) & {
            confirm?: undefined; // To disallow confirmable events here
        },
    >(event: Omit<T, "appName" | "targetApps">): void {
        this.eventBusClient.send(event.eventName, event["data"] ?? null, false);
    }

    private obtain<
        T extends (ITypedEvent | IDatalessTypedEvent) & {
            confirm?: undefined; // To disallow confirmable events here
        },
    >(
        eventName: T["eventName"]
    ): Observable<T extends ITypedEvent ? T["data"] : null> {
        return this.eventBusClient.receive.pipe(
            filter((event: IEvent) => event.eventName === eventName),
            map((event) => event.data ?? null)
        );
    }

    public obtainConfirmable<
        T extends
            | ITypedConfirmableEvent<unknown>
            | IDatalessTypedConfirmableEvent<unknown>,
    >(eventName: T["eventName"]): Observable<T> {
        return this.eventBusClient.receive.pipe(
            filter((event: IEvent) => event.eventName === eventName)
        ) as Observable<T>;
    }
}
