import {
    ReportGrouping,
    ReportGroupingConversion,
    ReportGroupingCustomVariable,
    ReportGroupingEntity,
    ReportGroupingOther,
} from "./report-grouping.definition";

export const REPORT_GROUPINGS_NAMES: { [key in ReportGrouping]: string } = {
    // Entity
    [ReportGroupingEntity.CAMPAIGN]: "Campaigns",
    [ReportGroupingEntity.OFFER]: "Offers",
    [ReportGroupingEntity.LANDER]: "Landers",
    [ReportGroupingEntity.FLOW]: "Flows",
    [ReportGroupingEntity.TRAFFIC_SOURCE]: "Traffic sources",
    [ReportGroupingEntity.AFFILIATE_NETWORK]: "Affiliate networks",
    [ReportGroupingEntity.SUB_LANDER]: "Sublanders",

    // Reports
    [ReportGroupingOther.COUNTRY]: "Country",
    [ReportGroupingOther.TRAFFIC_TYPE]: "Traffic type",
    [ReportGroupingOther.VERTICAL]: "Vertical market",
    [ReportGroupingOther.ISP]: "ISP / Carrier",
    [ReportGroupingOther.MOBILE_CARRIER]: "Mobile carrier",
    [ReportGroupingOther.CONNECTION_TYPE]: "Connection type",
    [ReportGroupingOther.PROXY]: "Proxy",
    [ReportGroupingOther.DEVICE]: "Device types",
    [ReportGroupingOther.BRAND]: "Brands",
    [ReportGroupingOther.MODEL]: "Models",
    [ReportGroupingOther.OS]: "OS",
    [ReportGroupingOther.OS_VERSION]: "OS versions",
    [ReportGroupingOther.BROWSER]: "Browsers",
    [ReportGroupingOther.BROWSER_VERSION]: "Browser versions",
    [ReportGroupingOther.DAY]: "Day",
    [ReportGroupingOther.MONTH]: "Month",
    [ReportGroupingOther.DAY_OF_WEEK]: "Day of week",
    [ReportGroupingOther.HOUR_OF_DAY]: "Hour of day",
    [ReportGroupingOther.LIVE_VISITS]: "Live visits",
    [ReportGroupingOther.LIVE_CLICKS]: "Live clicks",
    [ReportGroupingOther.IP]: "IP",
    [ReportGroupingOther.LANGUAGE]: "Language",
    [ReportGroupingOther.REGION]: "State / Region",
    [ReportGroupingOther.CITY]: "City",
    [ReportGroupingOther.PATH]: "Paths",
    [ReportGroupingOther.REFERRER]: "Referrer",
    [ReportGroupingOther.REFERRER_DOMAIN]: "Referrer domain",
    [ReportGroupingOther.TRACKING_DOMAIN]: "Tracking domain",

    //Conversions
    [ReportGroupingConversion.CONVERSION]: "Conversions",
    [ReportGroupingConversion.CLICK_ID]: "Click ID",
    [ReportGroupingConversion.TRANSACTION_ID]: "Transaction ID",
    [ReportGroupingConversion.EXTERNAL_ID]: "External ID",
    [ReportGroupingConversion.POSTBACK_PARAM_1]: "Postback Param 1",
    [ReportGroupingConversion.POSTBACK_PARAM_2]: "Postback Param 2",
    [ReportGroupingConversion.POSTBACK_PARAM_3]: "Postback Param 3",
    [ReportGroupingConversion.POSTBACK_PARAM_4]: "Postback Param 4",
    [ReportGroupingConversion.POSTBACK_PARAM_5]: "Postback Param 5",

    //Custom variable
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_1]: "V1",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_2]: "V2",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_3]: "V3",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_4]: "V4",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_5]: "V5",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_6]: "V6",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_7]: "V7",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_8]: "V8",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_9]: "V9",
    [ReportGroupingCustomVariable.CUSTOM_VARIABLE_10]: "V10",
};
