import { IAppConfig, Time } from "@codewise/voluum-frontend-core/app";
import { empty, interval, merge, Observable } from "rxjs";
import { catchError, filter, switchMap, takeUntil, tap } from "rxjs/operators";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
} from "../../../globals";
import { Ajax, IOptions } from "../../../service/ajax";
import { Authentication, encodeToken } from "../../../service/authentication";
import { EventBus } from "../../../service/event_bus";
import { HttpRequestErrorHandler } from "../../../service/http_request_error_handler";

const POLL_INTERVAL: number = 30 * 1000;

export class SessionMonitor {
    constructor(
        private appConfig: IAppConfig,
        private eventBus: EventBus,
        private time: Time,
        private httpRequestErrorHandler: HttpRequestErrorHandler
    ) {}

    public init(): void {
        merge(
            this.httpRequestErrorHandler.unauthorizedRequest$,
            this.sessionTimeout$
        )
            .pipe(takeUntil(this.eventBus.onSignOut))
            .subscribe(() => this.eventBus.showLoginForm());
    }

    public checkSessionStatus(): Observable<ISessionStatus> {
        const token: string = Authentication.getToken();
        let headers: IOptions["headers"];
        if (token) {
            headers = {
                [AUTHENTICATION_HEADER_NAME]: token,
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(token),
            };
        } else {
            headers = {
                [AUTHENTICATION_HEADER_NAME]: token,
            };
        }
        return Ajax.request({
            method: "GET",
            headers: headers,
            url: `${this.appConfig.server.portal}/auth/session`,
        }) as Observable<ISessionStatus>;
    }

    private get sessionTimeout$(): Observable<ISessionStatus> {
        return interval(POLL_INTERVAL).pipe(
            switchMap(() =>
                this.checkSessionStatus().pipe(catchError(() => empty()))
            ),
            tap((sessionStatus: ISessionStatus) => {
                this.time.setServerTime(sessionStatus.time);
            }),
            filter(
                (sessionStatus: ISessionStatus) => sessionStatus.alive !== true
            )
        );
    }
}

export interface ISessionStatus {
    alive: boolean;
    time: number;
    inaugural: boolean;
    // Indicates if user is logged in via admin - impersonated
    demo?: true;
}
