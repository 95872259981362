var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { AutoSerialize, AutoSerializeCollection, } from "@codewise/voluum-frontend-framework/repository";
import { FeatureEnabled } from "../../user/model";
import { CampaignTabEnum } from "./enum/campaign-tab.enum";
import { ReportPreferenceModel } from "./report-preference.model";
import { UserPreferenceBookmarksModel } from "./user-preference-bookmarks.model";
export class UserPreferenceModel {
    constructor() {
        this.reportPreference = new ReportPreferenceModel();
        this.newsFeedLastSeen = "";
        this.featuresShown = [];
        this.defaultCampaignTab = CampaignTabEnum.GENERAL;
        this.enabledFeatures = [];
        this.featuresRequested = [];
        this.groupings = [];
        this.bookmarks = new UserPreferenceBookmarksModel();
    }
    hasFeatureEnabled(feature) {
        return this.enabledFeatures.includes(feature);
    }
    isInFeatureShown(feature) {
        return this.featuresShown.includes(feature);
    }
    addFeatureShown(feature) {
        if (!this.isInFeatureShown(feature)) {
            this.featuresShown.push(feature);
        }
        return this.featuresShown;
    }
    removeFeatureShown(feature) {
        if (this.isInFeatureShown(feature)) {
            this.featuresShown.splice(this.featuresShown.indexOf(feature), 1);
        }
        return this.featuresShown;
    }
    get basicFormEnabled() {
        return !this.enabledFeatures.includes(FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE);
    }
    set basicFormEnabled(enabled) {
        const toggleDisabledIdx = this.enabledFeatures.indexOf(FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE);
        if (enabled) {
            if (toggleDisabledIdx !== -1) {
                this.enabledFeatures.splice(toggleDisabledIdx, 1);
            }
        }
        else {
            if (toggleDisabledIdx === -1) {
                this.enabledFeatures.push(FeatureEnabled.BASIC_FORM_DISABLED_TOGGLE);
            }
        }
    }
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_a = typeof ReportPreferenceModel !== "undefined" && ReportPreferenceModel) === "function" ? _a : Object)
], UserPreferenceModel.prototype, "reportPreference", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], UserPreferenceModel.prototype, "newsFeedLastSeen", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], UserPreferenceModel.prototype, "featuresShown", void 0);
__decorate([
    AutoSerialize({ circuitBreaker: () => String }),
    __metadata("design:type", typeof (_b = typeof CampaignTabEnum !== "undefined" && CampaignTabEnum) === "function" ? _b : Object)
], UserPreferenceModel.prototype, "defaultCampaignTab", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], UserPreferenceModel.prototype, "enabledFeatures", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Array)
], UserPreferenceModel.prototype, "featuresRequested", void 0);
__decorate([
    AutoSerializeCollection(String),
    __metadata("design:type", Array)
], UserPreferenceModel.prototype, "groupings", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", typeof (_c = typeof UserPreferenceBookmarksModel !== "undefined" && UserPreferenceBookmarksModel) === "function" ? _c : Object)
], UserPreferenceModel.prototype, "bookmarks", void 0);
