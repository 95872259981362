import { CustomVariableReportColumn } from "./custom-variable-report-column.enum";

export enum CustomVariable5ReportColumn {
    CUSTOM_VARIABLE_5 = "customVariable5",
    CUSTOM_VARIABLE_5_HASH = "customVariable5Hash",
    CUSTOM_VARIABLE_5_MARKER = "customVariable5Marker",
}

export type CustomVariable5ReportColumns =
    | CustomVariable5ReportColumn
    | CustomVariableReportColumn;
