import { Plan } from "@voluum-panel/profile/ng-free";
class PlanManager {
    get plan() {
        return this._plan;
    }
    get rawData() {
        return this._rawData;
    }
    parseResponse(planResponse) {
        this._rawData = planResponse.body;
        this._plan = Plan.deserializer(planResponse.body);
        return this._plan;
    }
    updatePlanData(planResponse) {
        if (this._plan) {
            this._plan.updatePlanData(planResponse);
            this.updateRawPlanData(planResponse);
        }
    }
    updateRawPlanData(planResponse) {
        var _a;
        Object.assign((_a = this.rawData) !== null && _a !== void 0 ? _a : {}, planResponse);
    }
}
export { PlanManager, Plan };
