export enum TrafficSourceReportColumn {
    TRAFFIC_SOURCE_ID = "trafficSourceId",
    TRAFFIC_SOURCE_NAME = "trafficSourceName",
    TRAFFIC_SOURCE_NOTES = "trafficSourceNotes",
    TRAFFIC_SOURCE_WORKSPACE_NAME = "trafficSourceWorkspaceName",
    TRAFFIC_SOURCE_WORKSPACE_ID = "trafficSourceWorkspaceId",
    TRAFFIC_SOURCE_CLICK_ID_ARGUMENT = "clickIdArgument",
    TRAFFIC_SOURCE_COST_ARGUMENT = "costArgument",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_1 = "customVariable1-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_2 = "customVariable2-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_3 = "customVariable3-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_4 = "customVariable4-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_5 = "customVariable5-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_6 = "customVariable6-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_7 = "customVariable7-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_8 = "customVariable8-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_9 = "customVariable9-TS",
    TRAFFIC_SOURCE_CUSTOM_VARIABLE_10 = "customVariable10-TS",
}
