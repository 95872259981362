import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

import { BillingAddress } from "./billing-address.model";

export class BillingInfo {
    @AutoSerialize()
    public name!: string;

    @AutoSerialize()
    public taxId!: string;

    @AutoSerialize()
    public address!: BillingAddress;
}
