import {
    AutoSerialize,
    AutoSerializeCollection,
} from "@codewise/voluum-frontend-framework/repository";

import { ColumnModel } from "./column.model";
import { ConversionRegistrationTimeEnum } from "./enum/conversion-registration-time.enum";
import { HomeScreenEnum } from "./enum/home-screen.enum";
import { MainScreenEnum } from "./enum/main-screen.enum";
import { ReportOpeningWayEnum } from "./enum/report-opening-way.enum";
import { StatusFilterEnum } from "./enum/status-filter.enum";
import { OrderedColumnsConfigurationModel } from "./ordered-columns-configuration.model";
import { Direction, SortModel } from "./sort.model";

/**
 * @see UserPreferenceModel
 * @see ConversionRegistrationTimeEnum
 * @see HomeScreenEnum
 * @see ReportOpeningWayEnum
 * @see OrderedColumnsConfigurationModel
 */
export class ReportPreferenceModel {
    @AutoSerialize({ circuitBreaker: () => String })
    public conversionRegistrationTime: ConversionRegistrationTimeEnum =
        ConversionRegistrationTimeEnum.CONVERSION;

    @AutoSerialize({ circuitBreaker: () => String })
    public homeScreen: HomeScreenEnum = HomeScreenEnum.DASHBOARD_CAMPAIGN;

    /**
     * Preference for the page that is being displayed after:
     * * clicking on Voluum logo
     * * logging in
     */
    @AutoSerialize({ circuitBreaker: () => String })
    public mainScreen: MainScreenEnum = MainScreenEnum.TRACKER;

    @AutoSerialize({ circuitBreaker: () => String })
    public reportOpeningWay: ReportOpeningWayEnum =
        ReportOpeningWayEnum.BOTH_OPTIONS;

    @AutoSerializeCollection(OrderedColumnsConfigurationModel)
    public orderedColumnsConfiguration!: OrderedColumnsConfigurationModel[];

    @AutoSerialize()
    public limit: number = 100;

    @AutoSerialize({ circuitBreaker: () => String })
    public filter: StatusFilterEnum = StatusFilterEnum.ACTIVE;

    @AutoSerializeCollection(ColumnModel)
    public columns: ColumnModel[] = [];

    @AutoSerialize()
    public sort: SortModel = {
        columnName: "",
        direction: Direction.ASC,
    };
}
