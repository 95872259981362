import { Workspace } from "@voluum-panel/profile/ng-free";
import { BehaviorSubject } from "rxjs";
export class WorkspacesManager {
    constructor(eventBus) {
        this.eventBus = eventBus;
        this.workspaces$ = new BehaviorSubject([]);
        this.selectedWorkspaces$ = new BehaviorSubject([]);
        this.selectedWorkspaces = [];
        this.workspaces = [];
        this.eventBus.onUpdateWorkspacesList.subscribe((data) => {
            this.onUpdateWorkspacesList(data);
        });
    }
    parseResponseAndAssignWorkspaces(response) {
        if (response.status !== 200) {
            return;
        }
        this.workspaces = response.body.workspaces
            .map((ws) => new Workspace(ws.id, ws.name))
            .sort(this.sortWorkspacesByName);
        this.workspaces$ = new BehaviorSubject(this.workspaces);
        this.selectedWorkspaces$ = new BehaviorSubject(this.workspaces);
        if (location.hash.includes("ws=") &&
            location.hash.split("ws=")[1] !== "") {
            const selectedWorkspacesIds = location.hash
                .split("ws=")
                .splice(1)
                .map((id) => id.slice(0, 36));
            this.selectedWorkspaces = this.workspaces.filter((ws) => selectedWorkspacesIds.includes(ws.id));
            if (!this.selectedWorkspaces.length) {
                this.selectedWorkspaces = this.workspaces;
            }
        }
        else {
            this.selectedWorkspaces = this.workspaces;
        }
        this.updateWorkspaces(this.workspaces, this.selectedWorkspaces);
    }
    updateWorkspaces(workspaces, selectedWorkspaces) {
        this.selectedWorkspaces = selectedWorkspaces;
        this.selectedWorkspaces$.next(this.selectedWorkspaces);
        this.workspaces = workspaces;
        this.workspaces$.next(this.workspaces);
    }
    onUpdateWorkspacesList(newWorkspacesList) {
        let newWorkspace;
        this.workspaces = this.workspaces.map((ws) => new Workspace(ws.id, ws.name));
        newWorkspacesList = newWorkspacesList.map((ws) => new Workspace(ws.id, ws.name));
        if (this.workspaces.length === newWorkspacesList.length) {
            if (this.workspaces.length === this.selectedWorkspaces.length) {
                this.selectedWorkspaces = newWorkspacesList;
            }
        }
        else {
            newWorkspace = newWorkspacesList.find((ws) => !this.workspaces.includes(ws));
            if (newWorkspace) {
                this.selectedWorkspaces.push(newWorkspace);
            }
        }
        this.updateWorkspaces(newWorkspacesList, this.selectedWorkspaces);
    }
    sortWorkspacesByName(workspaceA, workspaceB) {
        var _a, _b, _c, _d;
        const workspaceAName = (_b = (_a = workspaceA.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : "";
        const workspaceBName = (_d = (_c = workspaceB.name) === null || _c === void 0 ? void 0 : _c.toLowerCase()) !== null && _d !== void 0 ? _d : "";
        if (workspaceAName < workspaceBName) {
            return -1;
        }
        else if (workspaceAName > workspaceBName) {
            return 1;
        }
        else {
            return 0;
        }
    }
}
