export class Url {
    private _paramsMap?: Map<string, string>;

    private get paramsMap(): Map<string, string> {
        if (this._paramsMap) {
            return this._paramsMap;
        }
        return (this._paramsMap = new Map(this.fetchUrlParams()));
    }

    public get params(): Record<string, string> | null {
        if (this.paramsMap.size === 0) {
            return null;
        }
        return Array.from(this.paramsMap.entries()).reduce(
            (all, [key, value]) => ({ ...all, [key]: value }),
            {}
        );
    }

    constructor(private readonly location: Location = window.location) {}

    public has(paramName: string): boolean {
        return this.paramsMap.has(paramName);
    }

    public get(paramName: string): string | undefined {
        return this.paramsMap.get(paramName);
    }

    private fetchUrlParams(): [string, string][] {
        const searchQuery: string = this.location.search.substr(1);
        if (searchQuery === "") {
            return [];
        }
        return searchQuery
            .split("&")
            .map((tuple) => tuple.split("="))
            .map((pair) => pair.map(decodeURIComponent) as [string, string]);
    }
}
