export enum ReportGroupingEntity {
    CAMPAIGN = "campaign",
    OFFER = "offer",
    LANDER = "lander",
    FLOW = "flow",
    TRAFFIC_SOURCE = "traffic-source",
    AFFILIATE_NETWORK = "affiliate-network",
    SUB_LANDER = "subLanderId",
}

export enum ReportGroupingOther {
    COUNTRY = "country-code",
    TRAFFIC_TYPE = "traffic-type",
    VERTICAL = "vertical",
    ISP = "isp",
    MOBILE_CARRIER = "mobile-carrier",
    CONNECTION_TYPE = "connection-type",
    PROXY = "proxy",
    DEVICE = "device",
    BRAND = "brand",
    MODEL = "model",
    OS = "os",
    OS_VERSION = "os-version",
    BROWSER = "browser",
    BROWSER_VERSION = "browser-version",
    DAY = "day",
    MONTH = "monthRoundedDate",
    DAY_OF_WEEK = "day-of-week",
    HOUR_OF_DAY = "hour-of-day",
    // Prefix with slash is necessary to have compatibility with tracker
    // TODO: Remove this prefix after whole migration (-> live-visits/clicks)
    LIVE_VISITS = "live-events/visits",
    LIVE_CLICKS = "live-events/clicks",
    IP = "ip",
    LANGUAGE = "language",
    REGION = "region",
    CITY = "city",
    PATH = "path",
    REFERRER = "referrer",
    REFERRER_DOMAIN = "referrer-domain",
    TRACKING_DOMAIN = "clientDomain",
}

export enum ReportGroupingConversion {
    CONVERSION = "conversion",
    CLICK_ID = "clickId",
    TRANSACTION_ID = "transactionId",
    EXTERNAL_ID = "externalId",
    POSTBACK_PARAM_1 = "postbackParam1",
    POSTBACK_PARAM_2 = "postbackParam2",
    POSTBACK_PARAM_3 = "postbackParam3",
    POSTBACK_PARAM_4 = "postbackParam4",
    POSTBACK_PARAM_5 = "postbackParam5",
}

export enum ReportGroupingCustomVariable {
    CUSTOM_VARIABLE_1 = "custom-variable-1",
    CUSTOM_VARIABLE_2 = "custom-variable-2",
    CUSTOM_VARIABLE_3 = "custom-variable-3",
    CUSTOM_VARIABLE_4 = "custom-variable-4",
    CUSTOM_VARIABLE_5 = "custom-variable-5",
    CUSTOM_VARIABLE_6 = "custom-variable-6",
    CUSTOM_VARIABLE_7 = "custom-variable-7",
    CUSTOM_VARIABLE_8 = "custom-variable-8",
    CUSTOM_VARIABLE_9 = "custom-variable-9",
    CUSTOM_VARIABLE_10 = "custom-variable-10",
}

export type ReportGrouping =
    | ReportGroupingEntity
    | ReportGroupingOther
    | ReportGroupingConversion
    | ReportGroupingCustomVariable;

export const REPORT_GROUPINGS: ReportGrouping[] = [
    ...Object.values(ReportGroupingEntity),
    ...Object.values(ReportGroupingOther),
    ...Object.values(ReportGroupingConversion),
    ...Object.values(ReportGroupingCustomVariable),
];
