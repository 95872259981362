import {
    AutoSerialize,
    Serializer,
} from "@codewise/voluum-frontend-framework/repository";
import {
    Length,
    NestedValidation,
    Required,
} from "@codewise/voluum-frontend-framework/validation";

import { ColumnExpression } from "./custom-column-expression.model";
import { CustomColumnFormat } from "./custom-column-format.model";

export class CustomColumn {
    @Required({
        propertyName: "Column name",
    })
    @Length({
        min: 2,
        max: 40,
        propertyName: "Column name",
    })
    @AutoSerialize()
    public label: string = "";

    @Required()
    @AutoSerialize()
    public name?: string;

    @AutoSerialize()
    public colored: boolean = false;

    public visible: boolean = true;

    @NestedValidation()
    @AutoSerialize()
    public expression: ColumnExpression = new ColumnExpression();

    @AutoSerialize({
        circuitBreaker: () => String,
    })
    public format: CustomColumnFormat = CustomColumnFormat.DECIMAL;

    public get snapshot(): string {
        return JSON.stringify(Serializer.serialize(this));
    }

    public clone(): CustomColumn {
        return Serializer.deserialize(CustomColumn, Serializer.serialize(this));
    }
}
