import { IAppConfig } from "@codewise/voluum-frontend-core/app";

import { Cookie } from "./cookie";

export const DEFAULT_TOKEN_COOKIE_NAME = "cw-token-cookie";

export class Authentication {
    private static TOKEN_COOKIE_NAME: string = DEFAULT_TOKEN_COOKIE_NAME;

    public static hasToken(): boolean {
        return typeof Authentication.getToken() === "string";
    }

    public static getToken(): string | undefined {
        return Cookie.get(Authentication.TOKEN_COOKIE_NAME);
    }

    public static setToken(value: string): void {
        Cookie.set(Authentication.TOKEN_COOKIE_NAME, value, {
            expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
            domain: window.location.hostname,
        });
    }

    public static removeToken(): void {
        Cookie.delete(Authentication.TOKEN_COOKIE_NAME);
    }

    public static initialize(config: IAppConfig): void {
        if (config && config.environment) {
            Authentication.TOKEN_COOKIE_NAME = DEFAULT_TOKEN_COOKIE_NAME;
        } else {
            throw new Error("Please provide a valid app config");
        }
    }
}

export const authenticationPanelTokenHeaderEncoder = (token: string) => ({
    "cwauth-panel-token": encodeToken(token),
});

/* eslint-disable */
export function encodeToken(e: string) {
    for (var a = 0, r = navigator.userAgent, t = 0; t < r.length; t++)
        (a = (a << 5) - a + r.charCodeAt(t)), (a |= 0);
    var o = atob(e.replace(/_/g, "/").replace(/-/g, "+")),
        n = "",
        g = Date.now();
    for (g *= Math.pow(2, g % 16), t = 0; t < o.length; t++)
        n += String.fromCharCode(
            o.charCodeAt(t) ^
                ((a >>> (8 * (3 - (t % 4)))) & 255) ^
                Math.floor((g / Math.pow(2, 8 * (7 - (t % 8)))) & 255)
        );
    return btoa(n).replace(/\//g, "_").replace(/\+/g, "-");
}
/* eslint-enable */
