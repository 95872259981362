var Workspace_1;
import { __decorate, __metadata } from "tslib";
import { ReferentiallyImmutable, ReferentiallyImmutableKey, } from "@codewise/voluum-frontend-framework/model";
import { EntityDeserializer, EntitySerializer, } from "@codewise/voluum-frontend-framework/repository";
let Workspace = Workspace_1 = class Workspace {
    static deserializer(data) {
        return new Workspace_1(data.id, data.name);
    }
    static isSharedWorkspace(workspace) {
        return !workspace || workspace === SHARED_WORKSPACE;
    }
    static create(id, name) {
        return id === SHARED_WORKSPACE.id
            ? SHARED_WORKSPACE
            : new Workspace_1(id, name);
    }
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
    serializer() {
        return Workspace_1.isSharedWorkspace(this) ? null : { id: this.id };
    }
    toString() {
        return Workspace_1.isSharedWorkspace(this) ? null : this.name;
    }
};
__decorate([
    ReferentiallyImmutableKey(),
    __metadata("design:type", String)
], Workspace.prototype, "id", void 0);
__decorate([
    EntitySerializer(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], Workspace.prototype, "serializer", null);
__decorate([
    EntityDeserializer(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Workspace)
], Workspace, "deserializer", null);
Workspace = Workspace_1 = __decorate([
    ReferentiallyImmutable(),
    __metadata("design:paramtypes", [String, String])
], Workspace);
export { Workspace };
export const SHARED_WORKSPACE = new Workspace("shared", "Not assigned to a workspace");
