import { Client } from "../../client/model";
import { IUserJSON, User } from "../../user/model";

export class Session {
    // Indicates if user is logged in via admin - impersonated
    public demo?: true;
    public currentClient!: Client;
    private _user!: User;

    get user(): User {
        return this._user;
    }

    public static deserializer(sessionObject: IUserJSON): Session {
        const session: Session = new Session();
        session._user = User.deserializer(sessionObject);
        return session;
    }
}
