import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

export class QuotaLimits {
    @AutoSerialize()
    public affiliateNetworks!: number;

    @AutoSerialize()
    public asyncReportsRequests!: number;

    @AutoSerialize()
    public autoRules!: number;

    @AutoSerialize()
    public campaigns!: number;

    @AutoSerialize()
    public columnConditionsPerAutoRule!: number;

    @AutoSerialize()
    public dimensionLists!: number;

    @AutoSerialize()
    public entitiesPerAutoRule!: number;

    @AutoSerialize()
    public flows!: number;

    @AutoSerialize()
    public ipRangesPerCondition!: number;

    @AutoSerialize()
    public ipUaEntriesPerRule!: number;

    @AutoSerialize()
    public ipUaRules!: number;

    @AutoSerialize()
    public landers!: number;

    @AutoSerialize()
    public offers!: number;

    @AutoSerialize()
    public sharedReports!: number;

    @AutoSerialize()
    public trafficLogDailyRequests!: number;

    @AutoSerialize()
    public trafficSources!: number;

    @AutoSerialize()
    public valuesPerDimensionList!: number;

    @AutoSerialize()
    public workspaces!: number;

    @AutoSerialize()
    public customDomains!: number;

    @AutoSerialize()
    public adminWorkerUserLimit!: number;

    @AutoSerialize()
    public memberUserLimit!: number;

    @AutoSerialize()
    public customDomainCertificates!: number;
}
