import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
} from "@codewise/voluum-frontend-framework/model";
import {
    AutoSerialize,
    EntityId,
} from "@codewise/voluum-frontend-framework/repository";
import { Required } from "@codewise/voluum-frontend-framework/validation";

@ReferentiallyImmutable()
export class ColumnsOrderTemplate {
    @ReferentiallyImmutableKey()
    @EntityId()
    @AutoSerialize()
    public id?: string;

    @AutoSerialize()
    @Required()
    public name!: string;

    @AutoSerialize()
    @Required()
    public orderedColumns: string[] = [];

    public toString(): string {
        return this.name;
    }
}
