import { Client, IClientJSON } from "../../client/model";
import { MembershipRole } from "./membership-role.type";

export class Membership {
    public static deserializer(membershipData: IMembershipJSON): Membership {
        membershipData.client.role = membershipData.role;
        return new Membership(
            membershipData.role,
            Client.deserializer(membershipData.client),
            membershipData.restrictedColumns
        );
    }

    constructor(
        private _role: MembershipRole,
        private _client: Client,
        private _restrictedColumns: IRestrictedColumns[]
    ) {}

    get role(): MembershipRole {
        return this._role;
    }

    get client(): Client {
        return this._client;
    }

    get restrictedColumns(): IRestrictedColumns[] {
        return this._restrictedColumns;
    }
}

export interface IRestrictedColumns {
    name: string;
}

export interface IMembershipJSON {
    role: MembershipRole;
    client: IClientJSON;
    restrictedColumns: IRestrictedColumns[];
}
