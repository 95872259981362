const defaultCookieOptions = {
    domain: window.location.hostname,
};
export class Cookie {
    static get(name) {
        return Cookie.service.get(name);
    }
    static set(name, value, cookieOptions = defaultCookieOptions) {
        return Cookie.service.set(name, value, cookieOptions);
    }
    static delete(name, cookieOptions = defaultCookieOptions) {
        return Cookie.service.expire(name, cookieOptions);
    }
}
Cookie.service = require("cookies-js");
