import {
    EntityDeserializer,
    EntitySerializer,
} from "@codewise/voluum-frontend-framework/repository";

export type UserPreferenceBookmarkTransfer = { [key: string]: string };

export class UserPreferenceBookmarksModel {
    public list: UserPreferenceBookmarkModel[];

    constructor(bookmarks: UserPreferenceBookmarkModel[] = []) {
        this.list = bookmarks;
    }

    @EntityDeserializer()
    public static deserializer(
        transfer: UserPreferenceBookmarkTransfer
    ): UserPreferenceBookmarksModel {
        const userPreferenceBookmarks: UserPreferenceBookmarksModel =
            new UserPreferenceBookmarksModel();
        if (!!transfer && Object.keys(transfer).length > 0) {
            for (const transferKey of Object.keys(transfer)) {
                userPreferenceBookmarks.list.push(
                    new UserPreferenceBookmarkModel(
                        transferKey,
                        transfer[transferKey]
                    )
                );
            }
        }
        return userPreferenceBookmarks;
    }

    @EntitySerializer()
    public serialize(): UserPreferenceBookmarkTransfer {
        const transfer: UserPreferenceBookmarkTransfer = {};
        for (const bookmark of this.list) {
            transfer[bookmark.name] = bookmark.url;
        }
        return transfer;
    }

    public isEmpty(): boolean {
        return this.list.length === 0;
    }
}

export class UserPreferenceBookmarkModel {
    public name: string;
    public url: string;

    constructor(name: string = "", url: string = "") {
        this.name = name;
        this.url = url;
    }

    public equals(compareWith: UserPreferenceBookmarkModel): boolean {
        if (!compareWith) {
            return false;
        }
        if (compareWith === this) {
            return true;
        }
        return this.name === compareWith.name && this.url === compareWith.url;
    }
}
