import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";

import type { IPromoResource } from "./promo-resource.interface";

export class PromoSummary {
    @AutoSerialize()
    public hubspotLists: number[] = [];

    @AutoSerialize({
        circuitBreaker: () => Object,
    })
    public hubspotProperties: Record<string, unknown> = {};

    @AutoSerialize({
        circuitBreaker: () => Object,
    })
    public promoResource: IPromoResource | undefined;
}
