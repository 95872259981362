import { EventBus } from "./event_bus";

export class LoginHandler {
    public static init(
        clientId: string,
        email: string,
        eventBus: EventBus
    ): void {
        const queryParams = new URLSearchParams(
            window.location.hash.split("?")[1]
        );
        const login: string | null = queryParams.get("login");
        const redirectTo: string | null = queryParams.get("redirectTo");

        // we want to track log-in action in Google Analytics
        if (login === "success") {
            eventBus.updateDataLayer({
                event: "GAEvent",
                eventCategory: "User",
                eventAction: "Log in",
                eventLabel: email,
            });
        }

        if (login === "success" && clientId && redirectTo) {
            window.location.replace("?clientId=" + clientId + "#" + redirectTo);
        }
    }
}
