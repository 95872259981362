export enum CampaignReportColumn {
    CAMPAIGN_ID = "campaignId",
    CAMPAIGN_NAME = "campaignName",
    CAMPAIGN_EXTERNAL_ID = "externalCampaignId",
    CAMPAIGN_NOTES = "campaignNotes",
    CAMPAIGN_EXTERNAL_STATUS = "externalStatus",
    CAMPAIGN_URL = "campaignUrl",
    CAMPAIGN_COUNTRY = "campaignCountry",
    CAMPAIGN_WORKSPACE_NAME = "campaignWorkspaceName",
    CAMPAIGN_WORKSPACE_ID = "campaignWorkspaceId",
    CAMPAIGN_PIXEL_URL = "pixelUrl",
    CAMPAIGN_CLICK_REDIRECT_TYPE = "clickRedirectType",
    CAMPAIGN_COST_MODEL = "costModel",
    CAMPAIGN_CPA = "cpa",
    CAMPAIGN_CPC = "cpc",
    CAMPAIGN_CPM = "cpm",
    CAMPAIGN_MARKER = "campaignIdMarker",
    CAMPAIGN_BID = "bid",
    CAMPAIGN_DAILY_BUDGET = "campaignDailyBudget",
    CAMPAIGN_TAGS = "campaignTags",
    WANDUUM_CAMPAIGN_AP1 = "wanduumCampaignAp1",
    WANDUUM_CAMPAIGN_AP2 = "wanduumCampaignAp2",
    WANDUUM_CAMPAIGN_AP3 = "wanduumCampaignAp3",
    WANDUUM_CAMPAIGN_AVERAGE_AP = "wanduumCampaignAverageAp",
    WANDUUM_CAMPAIGN_DEVICE_OFFER_COUNTRY_AP = "wanduumDeviceOfferCountryAp",
    WANDUUM_ESTIMATED_AP = "wanduumEstimatedAp",
    WANDUUM_ESTIMATED_ROI = "wanduumEstimatedRoi",
    WANDUUM_CAMPAIGN_BUDGET_FILL = "wanduumCampaignBudgetFill",
    WANDUUM_PARKING_PROVIDER_CAMPAIGN_STATUS = "wanduumParkingProviderCampaignStatus",
    WANDUUM_PENDING_AP_1 = "wanduumPendingAp1",
    WANDUUM_PENDING_AP_2 = "wanduumPendingAp2",
}
