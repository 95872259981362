export enum ReportNumericColumn {
    IMPRESSIONS = "impressions",
    MTTI = "mtti",
    TIME_TO_INSTALL_RANGE_0 = "timeToInstallRange0",
    TIME_TO_INSTALL_RANGE_1 = "timeToInstallRange1",
    TIME_TO_INSTALL_RANGE_2 = "timeToInstallRange2",
    VISITS = "visits",
    SUSPICIOUS_VISITS = "suspiciousVisitsPercentage",
    UNIQUE_VISITS = "uniqueVisits",
    CLICKS = "clicks",
    UNIQUE_CLICKS = "uniqueClicks",
    SUSPICIOUS_CLICKS = "suspiciousClicksPercentage",
    INTERMEDIATE_CLICKS = "intermediateClicks",
    CONVERSION = "conversions",
    REVENUE = "revenue",
    COST = "cost",
    PROFIT = "profit",
    CPV = "cpv",
    ICTR = "ictr",
    CTR = "ctr",
    SCTR = "sctr",
    CR = "cr",
    CV = "cv",
    ROI = "roi",
    EPV = "epv",
    EPC = "epc",
    AP = "ap",
    ERRORS = "errors",
    RPM = "rpm",
    ECPM = "ecpm",
    ECPC = "ecpc",
    ECPA = "ecpa",
}

export enum ReportNumericComparisonColumn {
    VISITS_CHANGE = "visitsDelta",
    CONVERSION_CHANGE = "conversionsDelta",
    CV_CHANGE = "cvDelta",
}

export enum ComparisonColumnSuffix {
    DELTA = "Delta",
}

export enum ReportColumn {
    ENTITY_STATUS = "entityStatus",
    INTEGRATION_STATUS = "status",
    ACTIONS = "actions",
    CREATION_DATE = "created",
    POSTBACK_URL = "postbackUrl",
    VISIT_TIMESTAMP = "visitTimestamp",
    USER_AGENT = "userAgent",
    TIMESTAMP = "timestamp",
    PROFITABILITY_INDICATOR = "profitability-indicator",
}

export type ReportNumericColumns =
    | ReportNumericColumn
    | ReportNumericComparisonColumn;

export type ReportColumns = ReportColumn | ReportNumericColumns;

export enum ReportNonColumnField {
    SUSPICIOUS_VISITS = "suspiciousVisits",
    SUSPICIOUS_CLICKS = "suspiciousClicks",
    DATA_TYPE = "type",
    DELETED = "deleted",
    READONLY = "readOnly",
    CAMPAIGN_URL_CONFIGURED = "campaignUrlConfigured",
}

// Column not used in reports definition
// Added automatically during switching to tree view
// Behind it, there are grouping columns from different groupings
export enum SpecialColumn {
    NAME = "report-grouping-name",
}

// Wrapped into IIFE to perform this ordering only once
export const SORTED_NUMERIC_COLUMNS: ReportNumericColumns[] = (function () {
    const comparisonColumnsReferenceMapping: Record<
        ReportNumericComparisonColumn,
        ReportNumericColumn
    > = {
        [ReportNumericComparisonColumn.VISITS_CHANGE]:
            ReportNumericColumn.VISITS,
        [ReportNumericComparisonColumn.CONVERSION_CHANGE]:
            ReportNumericColumn.CONVERSION,
        [ReportNumericComparisonColumn.CV_CHANGE]: ReportNumericColumn.CV,
    };
    const numericColumns: ReportNumericColumns[] =
        Object.values(ReportNumericColumn);

    Object.entries(comparisonColumnsReferenceMapping).forEach(
        ([comparisonColumn, mainColumn]: [
            ReportNumericComparisonColumn,
            ReportNumericColumn,
        ]) => {
            const comparisonColumnIndex = numericColumns.indexOf(mainColumn);

            numericColumns.splice(
                comparisonColumnIndex + 1,
                0,
                comparisonColumn
            );
        }
    );

    return numericColumns;
})();
