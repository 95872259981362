import { AutoSerialize } from "@codewise/voluum-frontend-framework/repository";
import { NestedValidation } from "@codewise/voluum-frontend-framework/validation";

import { OperandType } from "./custom-column-operand.model";
import { CustomColumnOperation } from "./custom-column-operation.model";

export class ColumnExpression {
    @AutoSerialize()
    public type: string = "binaryOperand";

    @NestedValidation()
    @AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => ColumnExpression,
    })
    public left?: ColumnExpression;

    @NestedValidation()
    @AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => ColumnExpression,
    })
    public right?: ColumnExpression;

    @AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.COLUMN,
    })
    public name?: string;

    @AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.VALUE,
    })
    public doubleValue?: number;

    @AutoSerialize({
        unless: (_, ccOperand) => ccOperand.type !== OperandType.OPERAND,
        circuitBreaker: () => String,
    })
    public operation: CustomColumnOperation = CustomColumnOperation.ADDITION;
}
